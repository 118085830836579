import { exists, get } from "../utils/Storage";
import axios from "axios";
import _ from "linq";

class GoServer {
  constructor() {
    this.EndPoints = [
      {
        name: "Roles",
        url: "/roles",
        static: true,
      },
      {
        name: "Routes",
        url: "/route",
      },
      {
        name: "Stations",
        url: "/station",
      },
      {
        name: "Classes",
        url: "/classes",
        static: true,
      },
      {
        name: "Streams",
        url: "/streams",
        static: true,
      },
      {
        name: "Providers",
        url: "/listproviders",
        static: true,
      },
      {
        name: "Vehicles",
        url: "/vehicle",
      },
      {
        name: "StudentTypes",
        url: "/student/types",
      },
      {
        name: "Users",
        url: "/users",
      },
      {
        name: "Schedules",
        url: "/m/schedule",
      },
      {
        name: "Guardians",
        url: "/guardian",
      },
      {
        name: "Students",
        url: "/students",
        defer: true,
        filter: (student) => {
          alert("Yaay");

          if (!exists("admin")) return false;

          const session = get("admin");
          const user = session.user.providerid;

          return _.from(student.schedules).any((schedule) =>
            _.from("vehicles").any(
              (vehicle) => vehicle.ProviderId.toString() === user.ProviderId
            )
          );
        },
      },
    ];

    // a binary function check of missing tables
    this.checkEndPoints = (tables) => {
      let missing = [];
      tables.forEach((table) => {
        if (!table.defer) {
          if (!exists(table.name.toLowerCase())) {
            missing.push(table);
          }
        }
      });
      return missing;
    };

    this.isEndPointsBroken = () =>
      this.checkEndPoints(this.EndPoints).length > 0;

    // Determine the base URL and authorization token based on the environment
    if (window.location.toString().toLowerCase().includes("://app")) {
      this.school = window.school = "Bunifu Account";
      localStorage.setItem("school", "Bunifu Account");

      if (exists("admin")) {
        this.BASE_URL = axios.defaults.baseURL =
          "https://api.app.bunifugo.com/api";
        this.ACCESS_TOKEN = axios.defaults.headers.common["Authorization"] =
          "Bearer " + get("admin").access_token;
      } else {
        this.BASE_URL = axios.defaults.baseURL = "https://api.app.bunifugo.com";
      }
    } else if (window.location.toString().toLowerCase().includes("://mec")) {
      localStorage.setItem("firebase", "2-bunifu");
      this.school = window.school = "Moi Educational Centre";
      localStorage.setItem("school", "Moi Educational Centre");

      if (exists("admin")) {
        this.BASE_URL = axios.defaults.baseURL =
          "https://api.mec.bunifugo.com/api";
        this.ACCESS_TOKEN = axios.defaults.headers.common["Authorization"] =
          "Bearer " + get("admin").access_token;
      } else {
        this.BASE_URL = axios.defaults.baseURL = "https://api.mec.bunifugo.com";
      }
    } else if (window.location.toString().toLowerCase().includes("://kagaki")) {
      localStorage.setItem("firebase", "1-kagaki");
      this.school = window.school = "Kagaki School";
      localStorage.setItem("school", "Kagaki School");

      if (exists("admin")) {
        this.BASE_URL = axios.defaults.baseURL =
          "https://api.kagaki.bunifugo.com/api";
        this.ACCESS_TOKEN = axios.defaults.headers.common["Authorization"] =
          "Bearer " + get("admin").access_token;
      } else {
        this.BASE_URL = axios.defaults.baseURL =
          "https://api.kagaki.bunifugo.com";
      }
    } else if (
      window.location.toString().toLowerCase().includes("://bakhita")
    ) {
      localStorage.setItem("firebase", "3-Bakhita");
      this.school = window.school = "St Bakhita";
      localStorage.setItem("school", "St Bakhita");

      if (exists("admin")) {
        this.BASE_URL = axios.defaults.baseURL =
          "https://api.bakhita.bunifugo.com/api";
        this.ACCESS_TOKEN = axios.defaults.headers.common["Authorization"] =
          "Bearer " + get("admin").access_token;
      } else {
        this.BASE_URL = axios.defaults.baseURL =
          "https://api.bakhita.bunifugo.com";
      }
    } else if (window.location.toString().toLowerCase().includes("://darul")) {
      localStorage.setItem("firebase", "3-Darul-Ilmi");
      this.school = window.school = "Darul Ilmi Academy";
      localStorage.setItem("school", "Darul Ilmi Academy");

      if (exists("admin")) {
        this.BASE_URL = axios.defaults.baseURL =
          "https://api.darul-ilmi.bunifugo.com/api";
        this.ACCESS_TOKEN = axios.defaults.headers.common["Authorization"] =
          "Bearer " + get("admin").access_token;
      } else {
        this.BASE_URL = axios.defaults.baseURL =
          "https://api.darul-ilmi.bunifugo.com";
      }
    } else if (window.location.toString().toLowerCase().includes("://solian")) {
      localStorage.setItem("firebase", "3-solian");
      window.school = "Solian Girls Secondary";
      localStorage.setItem("school", "Solian Girls Secondary");

      if (exists("admin")) {
        this.BASE_URL = axios.defaults.baseURL =
          "https://api.solian.bunifugo.com/api";
        this.ACCESS_TOKEN = axios.defaults.headers.common["Authorization"] =
          "Bearer " + get("admin").access_token;
      } else {
        this.BASE_URL = axios.defaults.baseURL =
          "https://api.solian.bunifugo.com";
      }
    } else if (window.location.toString().toLowerCase().includes("://jga")) {
      localStorage.setItem("firebase", "5-jga");
      this.school = window.school = "Jonathan Gloag Academy";
      localStorage.setItem("school", "Jonathan Gloag Academy");

      if (exists("admin")) {
        this.BASE_URL = axios.defaults.baseURL =
          "https://api.jga.bunifugo.com/api";
        this.ACCESS_TOKEN = axios.defaults.headers.common["Authorization"] =
          "Bearer " + get("admin").access_token;
      } else {
        this.BASE_URL = axios.defaults.baseURL = "https://api.jga.bunifugo.com";
      }
    } else if (
      window.location.toString().toLowerCase().includes("://staging")
    ) {
      localStorage.setItem("firebase", "2-bunifu");
      this.school = window.school = "Staging Academy";
      localStorage.setItem("school", "Staging Academy");

      if (exists("admin")) {
        this.BASE_URL = axios.defaults.baseURL =
          "https://api.staging.bunifugo.com/api";
        this.ACCESS_TOKEN = axios.defaults.headers.common["Authorization"] =
          "Bearer " + get("admin").access_token;
      } else {
        this.BASE_URL = axios.defaults.baseURL =
          "https://api.staging.bunifugo.com";
      }
    } else if (window.location.toString().toLowerCase().includes("://jsps")) {
      localStorage.setItem("firebase", "2-jsps");
      this.school = window.school = "Juja St Peters School";
      localStorage.setItem("school", "Juja St Peters");

      if (exists("admin")) {
        this.BASE_URL = axios.defaults.baseURL =
          "https://api.jsps.bunifugo.com/api";
        this.ACCESS_TOKEN = axios.defaults.headers.common["Authorization"] =
          "Bearer " + get("admin").access_token;
      } else {
        this.BASE_URL = axios.defaults.baseURL =
          "https://api.jsps.bunifugo.com";
      }
    } else if (
      window.location.toString().toLowerCase().includes("://st-philips-neri")
    ) {
      localStorage.setItem("firebase", "st-philips");
      this.school = window.school = "St Philips Neri School";
      localStorage.setItem("school", "St Philips Neri");

      if (exists("admin")) {
        this.BASE_URL = axios.defaults.baseURL =
          "https://st-philips-neri-api.bunifugo.com/api";
        this.ACCESS_TOKEN = axios.defaults.headers.common["Authorization"] =
          "Bearer " + get("admin").access_token;
      } else {
        this.BASE_URL = axios.defaults.baseURL =
          "https://st-philips-neri-api.bunifugo.com";
      }
    } else if (
      window.location.toString().toLowerCase().includes("://sunshine")
    ) {
      localStorage.setItem("firebase", "sunshine");
      this.school = window.school = "Sunshine Primary School";
      if (exists("admin")) {
        this.BASE_URL = axios.defaults.baseURL =
          "https://api.sunshine.bunifugo.com/api";
        this.ACCESS_TOKEN = axios.defaults.headers.common["Authorization"] =
          "Bearer " + get("admin").access_token;
      } else {
        this.BASE_URL = axios.defaults.baseURL =
          "https://api.sunshine.bunifugo.com";
      }
    } else if (
      window.location.toString().toLowerCase().includes("://educabs")
    ) {
      localStorage.setItem("firebase", "7-educabs");
      this.school = window.school = "Edu Cabs";
      localStorage.setItem("school", "Edu Cabs");

      if (exists("admin")) {
        this.BASE_URL = axios.defaults.baseURL =
          "https://api.educabs.bunifugo.com/api";
        this.ACCESS_TOKEN = axios.defaults.headers.common["Authorization"] =
          "Bearer " + get("admin").access_token;
      } else {
        this.BASE_URL = axios.defaults.baseURL =
          "https://api.educabs.bunifugo.com";
      }
    } else if (window.location.toString().toLowerCase().includes("://test")) {
      localStorage.setItem("firebase", "8-test");
      this.school = window.school = "Test School";
      localStorage.setItem("school", "Test School");

      if (exists("admin")) {
        this.BASE_URL = axios.defaults.baseURL =
          "https://api.test.bunifugo.com/api";
        this.ACCESS_TOKEN = axios.defaults.headers.common["Authorization"] =
          "Bearer " + get("admin").access_token;
      } else {
        this.BASE_URL = axios.defaults.baseURL =
          "https://api.test.bunifugo.com";
      }
    } else {
      // Default to development environment
      localStorage.setItem("firebase", "5-jga");
      this.school = window.school = "Developer Inc";
      localStorage.setItem("school", "Developer Inc");

      if (exists("admin")) {
        this.BASE_URL = axios.defaults.baseURL =
          "https://api.jga.bunifugo.com/api";
        this.ACCESS_TOKEN = axios.defaults.headers.common["Authorization"] =
          "Bearer " + get("admin").access_token;
      } else {
        this.BASE_URL = axios.defaults.baseURL = "https://api.jga.bunifugo.com";
      }
    }

    axios.defaults.headers.post["Content-Type"] = "application/json";
    axios.defaults.headers.post["Accept"] = "application/json";
  }
}

export default new GoServer();
