import React, { useState, useEffect } from "react";
import Axios from "axios";
import {
  Button,
  Input,
  List,
  Card,
  Modal,
  message,
  Typography,
  Tooltip,
  Divider,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  MailOutlined,
  PhoneOutlined,
  HomeOutlined,
  CloseOutlined,
} from "@ant-design/icons";

const { Text } = Typography;

const ProviderList = () => {
  const [loading, setLoading] = useState(true);
  const [providers, setProviders] = useState([]);
  const [filteredProviders, setFilteredProviders] = useState([]);
  const [provider, setProvider] = useState(null);
  const [showManager, setShowManager] = useState(false);
  const [search, setSearch] = useState("");
  const [apiUrl, setApiUrl] = useState("");

  const colors = [
    "#FF5733",
    "#33FF57",
    "#3357FF",
    "#FF33A8",
    "#FFC300",
    "#DAF7A6",
    "#900C3F",
    "#581845",
    "#008080",
    "#800000",
    "#FF4500",
    "#6A5ACD",
  ];

  useEffect(() => {
    const storedApiUrl = localStorage.getItem("api");
    if (storedApiUrl) {
      setApiUrl(storedApiUrl);
    } else {
      message.error("Missing API URL. Please configure your settings.");
    }
  }, []);

  useEffect(() => {
    if (apiUrl) {
      fetchProviders();
    }
  }, [apiUrl]);

  const fetchProviders = async () => {
    setLoading(true);
    try {
      const response = await Axios.get(`${apiUrl}/providers`);
      const providersWithColors = response.data.map((p, index) => ({
        ...p,
        color: colors[index % colors.length], // Assign a color from the array
      }));

      setProviders(providersWithColors);
      setFilteredProviders(providersWithColors);
    } catch (error) {
      console.error("Error fetching providers:", error);
      message.error("Failed to fetch providers.");
    } finally {
      setLoading(false);
    }
  };
  const deleteProvider = async (id) => {
    try {
      await Axios.delete(`${apiUrl}/providers/${id}`);
      message.success("Provider deleted successfully!");

      // Reload the provider list
      fetchProviders();
    } catch (error) {
      console.error("Error deleting provider:", error);
      message.error("Failed to delete provider.");
    }
  };

  const handleSearch = (value) => {
    setSearch(value.toLowerCase());
    setFilteredProviders(
      providers.filter((p) =>
        p.Name.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const handleProviderChange = (e) => {
    const { name, value } = e.target;
    setProvider((prevProvider) => ({ ...prevProvider, [name]: value }));
  };

  const saveProvider = async () => {
    if (!provider || !provider.Name.trim()) {
      message.error("Provider Name is required");
      return;
    }

    try {
      let response;
      if (provider.id === -1) {
        response = await Axios.post(`${apiUrl}/providers`, provider);
      } else {
        response = await Axios.patch(
          `${apiUrl}/providers/${provider.id}`,
          provider
        );
      }

      message.success("Provider saved successfully!");

      // Close modal and reload providers
      setProvider(null);
      setShowManager(false);
      fetchProviders();
    } catch (error) {
      console.error("Error saving provider:", error);
      message.error("Failed to save provider.");
    }
  };

  return (
    <>
      <Modal
        visible={showManager}
        footer={null}
        onCancel={() => setShowManager(false)}
        width="60%"
        closable={true}
        maskClosable={false}
        title={provider ? `Edit ${provider.Name}` : "Add Provider"}
      >
        <Input
          name="Name"
          value={provider?.Name || ""}
          placeholder="Provider Name"
          onChange={handleProviderChange}
          style={{ marginBottom: "10px" }}
        />

        <Divider dashed />

        <MailOutlined style={{ color: "#00AF50", marginRight: 8 }} />
        <Input
          type="email"
          name="Email"
          value={provider?.Email || ""}
          placeholder="Enter Email Address"
          style={{ width: "90%", marginBottom: "10px" }}
          onChange={handleProviderChange}
        />

        <Divider dashed />

        <PhoneOutlined style={{ color: "#00AF50", marginRight: 8 }} />
        <Input
          type="tel"
          name="Phone"
          value={provider?.Phone || ""}
          placeholder="Enter Phone Number"
          style={{ width: "90%", marginBottom: "10px" }}
          onChange={handleProviderChange}
        />

        <Divider dashed />

        <HomeOutlined style={{ color: "#00AF50", marginRight: 8 }} />
        <Input
          name="Address"
          value={provider?.Address || ""}
          placeholder="Enter Address"
          style={{ width: "90%", marginBottom: "10px" }}
          onChange={handleProviderChange}
        />

        <Divider dashed />

        <Button type="primary" onClick={saveProvider}>
          {provider?.id === -1 ? "Add Provider" : "Save Changes"}
        </Button>
      </Modal>

      <div className="w-16" style={{ background: "#F0F2F5", padding: "30px" }}>
        <Input.Search
          placeholder="Search Providers..."
          enterButton
          onSearch={handleSearch}
          style={{ marginBottom: 20, width: "20%", paddingRight: "10px" }}
        />

        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setProvider({
              id: -1,
              Name: "",
              Address: "",
              Phone: "",
              Email: "",
            });
            setShowManager(true);
          }}
          style={{ marginBottom: 20 }}
        >
          Add Provider
        </Button>

        <List
          loading={loading}
          grid={{ gutter: 16, column: 3 }}
          dataSource={filteredProviders}
          renderItem={(item) => (
            <List.Item>
              <Card
                bordered={false}
                loading={loading}
                cover={
                  <div
                    align="center"
                    style={{
                      height: 200,
                      background: `radial-gradient(circle, ${item.color} 30%, rgb(0, 175, 80) 130%)`,
                      backgroundBlendMode: "multiply",
                      backgroundColor: item.color,
                    }}
                  >
                    <br />
                    <img
                      src={"/img/bus.png"}
                      alt="Bus Icon"
                      width={70}
                      style={{
                        marginTop: "30px",
                        filter: "drop-shadow(2px 2px 5px rgba(0,0,0,0.2))",
                      }}
                    />
                    <br />
                    <br />
                    <Text
                      style={{
                        color: "#ECEEF1",
                        fontWeight: "bold",
                        textShadow: `2px 2px ${item.color}`,
                      }}
                    >
                      {item.Name}
                    </Text>
                  </div>
                }
                actions={[
                  <Tooltip title="Edit">
                    <EditOutlined
                      style={{
                        color: "#00AF50",
                        marginRight: 8,
                        cursor: "pointer",
                        fontSize: "16px",
                      }}
                      onClick={() => {
                        setProvider(item);
                        setShowManager(true);
                      }}
                    />
                  </Tooltip>,
                  <Tooltip title="Delete">
                    <CloseOutlined
                      style={{
                        color: "red",
                        marginRight: 8,
                        cursor: "pointer",
                        fontSize: "16px",
                      }}
                      onClick={() => deleteProvider(item.id)}
                    />
                  </Tooltip>,
                ]}
              >
                <Card.Meta title={item.Name} description={item.Email} />
                <Divider dashed />
                <Text>
                  <MailOutlined style={{ color: item.color, marginRight: 8 }} />{" "}
                  {item.Email || "(None)"}
                </Text>
                <Divider dashed />
                <Text>
                  <PhoneOutlined
                    style={{ color: item.color, marginRight: 8 }}
                  />{" "}
                  {item.Phone || "(None)"}
                </Text>
                <Divider dashed />
                <Text>
                  <HomeOutlined style={{ color: item.color, marginRight: 8 }} />{" "}
                  {item.Address || "(None)"}
                </Text>
              </Card>
            </List.Item>
          )}
        />
      </div>
    </>
  );
};

export default ProviderList;
