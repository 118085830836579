import React from "react";
import { Card, Row, Col, Typography, Button } from "antd";
import {
  UserOutlined,
  BarChartOutlined,
  CarOutlined,
  PieChartOutlined,
  WarningOutlined,
  BookOutlined,
  WalletOutlined,
  LineChartOutlined as PerformanceIcon,
  SolutionOutlined,
  FileTextOutlined,
  FileAddOutlined,
} from "@ant-design/icons";

const { Title } = Typography;

const ReportsDashboard = () => {
  return (
    <div style={{ padding: "24px" }}>
      <Title level={3}>Reports Dashboard</Title>

      <Row gutter={[16, 16]} style={{ marginBottom: "24px" }}>
        <Col xs={24} sm={12} md={14}>
          <Card hoverable>
            <Row align="middle">
              <a href="/#/reports/prepare-report">
                <Col span={4} style={{ textAlign: "center" }}>
                  <FileAddOutlined
                    style={{ fontSize: "32px", color: "#1EBD60" }}
                  />
                </Col>
                <Col span={16}>
                  <Title level={4} style={{ marginBottom: 0 }}>
                    Prepare Billing Report
                  </Title>
                  <p style={{ margin: "8px 0", color: "gray" }}>
                    Start creating and customizing your reports with a few
                    clicks.
                  </p>
                </Col>
                <Col span={4} style={{ textAlign: "right" }}>
                  <Button type="primary" href="/#/reports/prepare-report">
                    Get Started
                  </Button>
                </Col>
              </a>
            </Row>
          </Card>
        </Col>

        <Col xs={24} sm={12} md={10}>
          <a href="/#/reports/distinct-reports">
            <Card hoverable>
              <Row align="middle">
                <Col span={4} style={{ textAlign: "center" }}>
                  <UserOutlined
                    style={{ fontSize: "32px", color: "#1EBD60" }}
                  />
                </Col>
                <Col span={16}>
                  <Title level={4} style={{ marginBottom: 0 }}>
                    View Past Billing Reports
                  </Title>
                  <p style={{ margin: "8px 0", color: "gray" }}>
                    View distribution of different student categories.
                  </p>
                </Col>
              </Row>
            </Card>
          </a>
        </Col>
      </Row>

      <div style={{ display: "flex", alignItems: "center", marginTop: "16px" }}>
        <BookOutlined
          style={{ fontSize: "24px", color: "black", marginRight: "8px" }}
        />
        <Title level={4} style={{ marginBottom: 0 }}>
          Student Reports
        </Title>
      </div>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={6}>
          <a href="/#/reports/students-type">
            <Card hoverable>
              <UserOutlined style={{ fontSize: "24px", color: "#1EBD60" }} />
              <Card.Meta
                title="Student Type"
                description="View distribution of different student categories"
              />
            </Card>
          </a>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <a href="/#/reports/students-type-summary">
            <Card hoverable>
              <BarChartOutlined
                style={{ fontSize: "24px", color: "#1EBD60" }}
              />
              <Card.Meta
                title="Student Type Summary"
                description="Detailed analysis of student demographics"
              />
            </Card>
          </a>
        </Col>

        <Col xs={24} sm={12} md={6}>
          <a href="/#/reports/students-activity">
            <Card hoverable>
              <FileTextOutlined
                style={{ fontSize: "24px", color: "#1EBD60" }}
              />
              <Card.Meta
                title="Student Activity"
                description="Detailed analysis of student activity"
              />
            </Card>
          </a>
        </Col>
      </Row>

      {/* <div style={{ display: "flex", alignItems: "center", marginTop: "24px" }}>
        <WalletOutlined
          style={{ fontSize: "24px", color: "black", marginRight: "8px" }}
        />
        <Title level={4} style={{ marginBottom: 0 }}>
          Billing Reports
        </Title>
      </div>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={6}>
          <a href="/#/reports/provider-summary">
            <Card hoverable>
              <SolutionOutlined
                style={{ fontSize: "24px", color: "#1EBD60" }}
              />
              <Card.Meta
                title="Provider Summary"
                description="Overview of provider performance metrics"
              />
            </Card>
          </a>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <a href="/#/reports/vehicle-summary">
            <Card hoverable>
              <CarOutlined style={{ fontSize: "24px", color: "#1EBD60" }} />
              <Card.Meta
                title="Vehicle Summary"
                description="Cost analysis per vehicle"
              />
            </Card>
          </a>
        </Col>

        <Col xs={24} sm={12} md={6}>
          <a href="/#/reports/vehicle-report">
            <Card hoverable>
              <FileTextOutlined
                style={{ fontSize: "24px", color: "#1EBD60" }}
              />
              <Card.Meta
                title="Vehicle Report"
                description="Manage and track student billing records"
              />
            </Card>
          </a>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <a href="/#/reports/student-report">
            <Card hoverable>
              <UserOutlined style={{ fontSize: "24px", color: "#1EBD60" }} />
              <Card.Meta
                title="Student Report"
                description="Manage and track student billing records"
              />
            </Card>
          </a>
        </Col>
      </Row> */}

      <div style={{ display: "flex", alignItems: "center", marginTop: "24px" }}>
        <PerformanceIcon
          style={{ fontSize: "24px", color: "black", marginRight: "8px" }}
        />
        <Title level={4} style={{ marginBottom: 0 }}>
          Performance Reports
        </Title>
      </div>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={6}>
          <a href="/#/reports/trip-summary">
            <Card hoverable>
              <PieChartOutlined
                style={{ fontSize: "24px", color: "#1EBD60" }}
              />
              <Card.Meta
                title="Trip Summary"
                description="Financial overview of completed trips"
              />
            </Card>
          </a>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <a href="/#/reports/vehicle-trips">
            <Card hoverable>
              <CarOutlined style={{ fontSize: "24px", color: "#1EBD60" }} />
              <Card.Meta
                title="Vehicle Trips"
                description="Analysis of vehicle utilization"
              />
            </Card>
          </a>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <a href="/#/reports/missed-trips">
            <Card hoverable>
              <WarningOutlined style={{ fontSize: "24px", color: "#1EBD60" }} />
              <Card.Meta
                title="Missed Trips"
                description="Track and analyze missed pickups/dropoffs"
              />
            </Card>
          </a>
        </Col>
      </Row>
    </div>
  );
};

export default ReportsDashboard;
