import React, { useState } from "react";
import axios from "axios";
import { Button, Input, Form, message, Spin, Typography } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

const { Title } = Typography;

const DownloadExcelReport = () => {
  const [databaseName, setDatabaseName] = useState("");
  const [uniqueReportId, setUniqueReportId] = useState("");
  const [loading, setLoading] = useState(false);
  const [baseUrl, setBaseUrl] = useState(localStorage.getItem("api") || "");
  const token = localStorage.getItem("account") ? JSON.parse(localStorage.getItem("account")).access_token : "";

  const handleDownload = async () => {
    if (!databaseName || !uniqueReportId) {
      message.warning("Please enter both Database Name and Unique Report ID.");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.get(`${baseUrl}/fastapi/download_excel`, {
        params: { database_name: databaseName, unique_report_id: uniqueReportId },
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        responseType: "blob", // Ensure response is treated as a file
      });

      // Create a downloadable link for the file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Report_${uniqueReportId}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      message.success("Report downloaded successfully.");
    } catch (error) {
      console.error("❌ Error downloading report:", error);
      message.error("Failed to download the report.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ width: "40%", margin: "50px auto", padding: "20px", border: "1px solid #ddd", borderRadius: "8px", backgroundColor: "#f9f9f9" }}>
      <Title level={3} style={{ textAlign: "center", marginBottom: 20 }}>Download Student Report</Title>

      <Form layout="vertical">
        <Form.Item label="Database Name" required>
          <Input
            placeholder="Enter Database Name"
            value={databaseName}
            onChange={(e) => setDatabaseName(e.target.value)}
          />
        </Form.Item>

        <Form.Item label="Unique Report ID" required>
          <Input
            placeholder="Enter Unique Report ID"
            value={uniqueReportId}
            onChange={(e) => setUniqueReportId(e.target.value)}
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            onClick={handleDownload}
            block
            disabled={!databaseName || !uniqueReportId}
            loading={loading}
          >
            {loading ? "Downloading..." : "Download Excel Report"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default DownloadExcelReport;
