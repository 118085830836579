import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Input,
  Alert,
  Select,
  AutoComplete,
  Empty,
  Spin,
  Col,
  Row,
  Table,
  message,
} from "antd";
import {
  exportToExcel,
  exportToCSV,
  exportToPDF,
} from "../../utils/generateReports";
import "antd/dist/antd.css";

const { Option } = Select;

const studentTypes = [
  { id: 1, label: "Paying" },
  { id: 2, label: "Not Paying" },
  { id: 3, label: "Sponsored" },
  { id: 4, label: "Boarding" },
];

const StudentType = () => {
  const getDate365DaysAgo = () => {
    const date = new Date();
    const millisecondsInADay = 24 * 60 * 60 * 1000;
    const pastDate = new Date(date.getTime() - 365 * millisecondsInADay);
    return pastDate.toISOString().split("T")[0];
  };

  // Component State
  const [busy, setBusy] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [text, setText] = useState("Generate Report");
  const [error, setError] = useState("");
  const [startDate, setStartDate] = useState(getDate365DaysAgo());
  const [endDate, setEndDate] = useState(
    new Date().toLocaleDateString("en-CA")
  );
  const [token, setToken] = useState("");
  const [students, setStudents] = useState([]);
  const [classes, setClasses] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState(students);
  const [baseUrl, setBaseUrl] = useState("");
  const [selectedStudent, setSelectedStudent] = useState("");
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedType, setSelectedType] = useState("");

  // Pagination States
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const [lastPage, setLastPage] = useState(1);

  const [studentData, setStudentData] = useState([]);

  useEffect(() => {
    const account = JSON.parse(localStorage.getItem("account"));
    const BaseUrl = localStorage.getItem("api");

    if (account && account.access_token) {
      setToken(account.access_token);
    } else {
      setError("Missing authentication token. Please log in again.");
      message.error("Missing authentication token. Please log in again.");
    }

    if (BaseUrl) {
      setBaseUrl(BaseUrl);
    } else {
      setError("Missing base URL. Please configure your settings.");
      message.error("Missing base URL. Please configure your settings.");
    }

    const storedStudents = JSON.parse(localStorage.getItem("students")) || [];
    const storedClasses = JSON.parse(localStorage.getItem("classes")) || [];
    setStudents(storedStudents);
    setClasses(storedClasses);
  }, []);

  useEffect(() => {
    setFilteredStudents(students);
  }, [students]);

  const handleStudentSearch = (value) => {
    if (!value) {
      setFilteredStudents(students);
    } else {
      const lowercasedValue = value.toLowerCase();
      const filtered = students.filter((student) =>
        student.Name.toLowerCase().includes(lowercasedValue)
      );
      setFilteredStudents(filtered);
    }
  };

  const generateReport = async (page = currentPage, limit = pageSize) => {
    if (!token) {
      setError("Missing authentication token. Please log in again.");
      message.error("Missing authentication token. Please log in again.");
      return;
    }

    setBusy(true);
    setText("Generating Report...");
    setError("");

    try {
      const url =
        `${baseUrl}/reports/student-types` +
        `?Student=${selectedStudent}` +
        `&Class=${selectedClass}` +
        `&Type=${selectedType}` +
        `&StartDate=${startDate}` +
        `&EndDate=${endDate}` +
        `&page=${page}` +
        `&limit=${limit}`;

      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const { data } = await axios.get(url, { headers });

      setStudentData(data.data || []);
      setCurrentPage(data.current_page);
      setPageSize(data.per_page);
      setTotalItems(data.total);
      setLastPage(data.last_page);
    } catch (error) {
      setError(
        `Error fetching data: ${error.response?.data?.message || error.message}`
      );
      message.error(
        `Error fetching data: ${error.response?.data?.message || error.message}`
      );
    } finally {
      setBusy(false);
      setText("Generate Report");
    }
  };

  const totalPages = Math.ceil(totalItems / pageSize);

  const getExportData = (data, isAllPages = false) => {
    return data.map((item, index) => ({
      serialNumber: isAllPages
        ? index + 1
        : (currentPage - 1) * pageSize + index + 1,
      ...item,
    }));
  };

  const exportCurrentPageExcel = () => {
    const augmentedData = getExportData(studentData, false);
    exportToExcel(augmentedData, "Student Type Report", startDate, endDate);
    message.success("Current page exported successfully as Excel.");
  };

  const exportCurrentPageCSV = () => {
    const augmentedData = getExportData(studentData, false);
    exportToCSV(augmentedData, "Student Type Report", startDate, endDate);
    message.success("Current page exported successfully as CSV.");
  };

  const exportCurrentPagePDF = () => {
    const augmentedData = getExportData(studentData, false);
    exportToPDF(
      exportColumns,
      augmentedData,
      "Student Type Report",
      "Student Type Report",
      [0, 0, 255],
      "/img/go-green.png",
      startDate,
      endDate
    );
    message.success("Current page exported successfully as PDF.");
  };

  const fetchAllData = async () => {
    setBusy(true);
    let allData = [];
    try {
      for (let page = 1; page <= lastPage; page++) {
        const url =
          `${baseUrl}/reports/student-types` +
          `?Student=${selectedStudent}` +
          `&Class=${selectedClass}` +
          `&Type=${selectedType}` +
          `&StartDate=${startDate}` +
          `&EndDate=${endDate}` +
          `&page=${page}` +
          `&limit=${pageSize}`;

        const res = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        allData = allData.concat(res.data.data);
      }

      return allData;
    } catch (error) {
      setError(
        `Error fetching all pages: ${
          error.response?.data?.message || error.message
        }`
      );
      message.error(
        `Error fetching all pages: ${
          error.response?.data?.message || error.message
        }`
      );
      return null;
    } finally {
      setBusy(false);
    }
  };

  // Export Handlers for All Pages
  const exportAllPagesExcel = async () => {
    setExporting(true);
    const allData = await fetchAllData();
    if (allData && allData.length > 0) {
      const augmentedData = getExportData(allData, true);
      exportToExcel(augmentedData, "AllPagesData", startDate, endDate);
      message.success("All pages exported successfully as Excel.");
    } else {
      message.warning("No data available to export.");
    }
    setExporting(false);
  };

  const exportAllPagesCSV = async () => {
    setExporting(true);
    const allData = await fetchAllData();
    if (allData && allData.length > 0) {
      const augmentedData = getExportData(allData, true);
      exportToCSV(augmentedData, "AllPagesData", startDate, endDate);
      message.success("All pages exported successfully as CSV.");
    } else {
      message.warning("No data available to export.");
    }
    setExporting(false);
  };

  const exportAllPagesPDF = async () => {
    setExporting(true);
    const allData = await fetchAllData();
    if (allData && allData.length > 0) {
      const augmentedData = getExportData(allData, true);
      exportToPDF(
        exportColumns,
        augmentedData,
        "AllPagesData",
        "All Pages Data Report",
        [0, 0, 255],
        "/img/go-green.png",
        startDate,
        endDate
      );
      message.success("All pages exported successfully as PDF.");
    } else {
      message.warning("No data available to export.");
    }
    setExporting(false);
  };

  const exportColumns = [
    {
      title: "S.No",
      dataIndex: "serialNumber",
      key: "serialNumber",
      width: 50,
    },
    {
      title: "Adm",
      dataIndex: "Adm",
      key: "Adm",
      width: 100,
    },
    {
      title: "Student",
      dataIndex: "Student",
      key: "Student",
      width: 200,
    },
    {
      title: "Class",
      dataIndex: "Class",
      key: "Class",
      width: 100,
    },
    {
      title: "Stream",
      dataIndex: "Stream",
      key: "Stream",
      width: 100,
    },
    {
      title: "Type",
      dataIndex: "Type",
      key: "Type",
      width: 100,
    },
    {
      title: "Gender",
      dataIndex: "Gender",
      key: "Gender",
      width: 100,
    },
    {
      title: "Subscription",
      dataIndex: "Subscription",
      key: "Subscription",
      width: 150,
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      width: 100,
    },
    {
      title: "Station",
      dataIndex: "Station",
      key: "Station",
      width: 150,
    },
    {
      title: "Route",
      dataIndex: "Route",
      key: "Route",
      width: 150,
    },
  ];

  const studentColumns = [
    {
      title: "#",
      key: "index",
      width: 60,
      render: (text, record, index) => (
        <span>{(currentPage - 1) * pageSize + index + 1}</span>
      ),
    },
    {
      title: "Adm",
      dataIndex: "Adm",
      key: "Adm",
      width: 100,
    },
    {
      title: "Student",
      dataIndex: "Student",
      key: "Student",
      width: 150,
    },
    {
      title: "Class",
      dataIndex: "Class",
      key: "Class",
      width: 70,
    },
    {
      title: "Stream",
      dataIndex: "Stream",
      key: "Stream",
      width: 70,
    },
    {
      title: "Type",
      dataIndex: "Type",
      key: "Type",
      width: 100,
    },
    {
      title: "Gender",
      dataIndex: "Gender",
      key: "Gender",
      width: 80,
    },
    {
      title: "Subscription",
      dataIndex: "Subscription",
      key: "Subscription",
      width: 150,
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      width: 90,
    },
    {
      title: "Station",
      dataIndex: "Station",
      key: "Station",
      width: 150,
    },
    {
      title: "Route",
      dataIndex: "Route",
      key: "Route",
      width: 150,
    },
  ];

  return (
    <div style={{ marginTop: 30 }}>
      <h4 style={{ textAlign: "center", marginBottom: 20 }}>
        Student Type Report
      </h4>

      <div style={{ width: "50%", margin: "0 auto", marginTop: 30 }}>
        <Row gutter={[16, 16]} style={{ marginBottom: "40px" }}>
          <Col span={12}>
            <label htmlFor="startDate">Start Date</label>
            <Input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              style={{ width: "100%" }}
            />
          </Col>

          <Col span={12}>
            <label htmlFor="endDate">End Date</label>
            <Input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              style={{ width: "100%" }}
            />
          </Col>

          <Col span={12}>
            <label htmlFor="studentType">Student Type</label>
            <Select
              placeholder="Select a student type..."
              style={{ width: "100%" }}
              onChange={setSelectedType}
              value={selectedType}
            >
              <Option value="">All</Option>
              {studentTypes.map((type) => (
                <Option key={type.id} value={type.label}>
                  {type.label}
                </Option>
              ))}
            </Select>
          </Col>

          <Col span={12}>
            <label htmlFor="students">Students</label>
            <AutoComplete
              placeholder="Search for a student..."
              style={{ width: "100%" }}
              onSearch={handleStudentSearch}
              onChange={(value) => setSelectedStudent(value)}
              value={selectedStudent}
            >
              {filteredStudents.map((student) => (
                <AutoComplete.Option key={student.id} value={student.Adm}>
                  {student.Name}
                </AutoComplete.Option>
              ))}
            </AutoComplete>
          </Col>

          <Col span={12}>
            <label htmlFor="class">Class</label>
            <Select
              placeholder="Select Class"
              style={{ width: "100%" }}
              onChange={setSelectedClass}
              value={selectedClass}
            >
              <Option value="">All</Option>
              {classes.map((classItem) => (
                <Option key={classItem} value={classItem}>
                  {classItem}
                </Option>
              ))}
            </Select>
          </Col>

          <Col span={12}>
            <Button
              type="primary"
              onClick={() => generateReport(1, pageSize)}
              loading={busy}
              disabled={!token || busy}
              style={{ width: "100%", marginTop: "26px" }}
            >
              {text}
            </Button>
          </Col>
        </Row>
      </div>

      <hr />

      {error && <Alert message={error} type="error" showIcon />}

      <Spin spinning={busy || exporting}>
        {studentData.length > 0 ? (
          <div
            style={{
              width: "90%",
              margin: "auto",
              marginTop: "40px",
              overflowX: "auto",
            }}
          >
            <Row justify="end" style={{ marginBottom: 20 }}>
              <Col>
                <Button
                  type="primary"
                  onClick={exportCurrentPageExcel}
                  style={{ marginRight: 10 }}
                  disabled={exporting}
                >
                  Export Current Page (Excel)
                </Button>
                <Button
                  type="primary"
                  onClick={exportCurrentPageCSV}
                  style={{ marginRight: 10 }}
                  disabled={exporting}
                >
                  Export Current Page (CSV)
                </Button>
                <Button
                  type="primary"
                  onClick={exportCurrentPagePDF}
                  style={{ marginRight: 10 }}
                  disabled={exporting}
                >
                  Export Current Page (PDF)
                </Button>

                <Button
                  type="default"
                  onClick={exportAllPagesExcel}
                  style={{ marginRight: 10 }}
                  disabled={exporting}
                >
                  Export All Pages (Excel)
                </Button>
                <Button
                  type="default"
                  onClick={exportAllPagesCSV}
                  style={{ marginRight: 10 }}
                  disabled={exporting}
                >
                  Export All Pages (CSV)
                </Button>
                <Button
                  type="default"
                  onClick={exportAllPagesPDF}
                  style={{ marginRight: 10 }}
                  disabled={exporting}
                >
                  Export All Pages (PDF)
                </Button>
              </Col>
            </Row>

            <Table
              columns={studentColumns}
              dataSource={studentData}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: totalItems,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "50", "100"],
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
              }}
              onChange={(pagination) => {
                const { current, pageSize } = pagination;
                generateReport(current, pageSize);
              }}
              rowKey="Adm"
              bordered
            />
          </div>
        ) : (
          <div style={{ textAlign: "center", marginTop: 40 }}>
            <Empty
              description="No data available. Please generate a report."
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </div>
        )}
      </Spin>
    </div>
  );
};
export default StudentType;
