import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Input,
  Alert,
  Row,
  Menu,
  Dropdown,
  Empty,
  Table,
  Form,
  Col,
  Spin,
  DatePicker,
  message,
  Modal,
} from "antd";
import "antd/dist/antd.css";
import DistinctReports from "./DistincReports";
import moment from "moment";

const { RangePicker } = DatePicker;

const ReportProcessing = () => {
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [token, setToken] = useState("");
  const [baseUrl, setBaseUrl] = useState("");
  const [databaseName, setDatabaseName] = useState("Database");
  const [reportAlias, setReportAlias] = useState("");
  const [dateRange, setDateRange] = useState([]);
  const [noTranches, setNoTranches] = useState(1);
  const [percentage, setPercentage] = useState(1);
  const [toSchoolMinTrips, setToSchoolMinTrips] = useState(1);
  const [toSchoolMinDays, setToSchoolMinDays] = useState(1);
  const [fromSchoolMinTrips, setFromSchoolMinTrips] = useState(1);
  const [fromSchoolMinDays, setFromSchoolMinDays] = useState(1);
  const [toFromSchoolMinTrips, setToFromSchoolMinTrips] = useState(1);
  const [toFromSchoolMinDays, setToFromSchoolMinDays] = useState(1);
  const [reportData, setReportData] = useState([]);
  const [showDistinctReports, setShowDistinctReports] = useState(false);
  const [currentReportId, setCurrentReportId] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);

  useEffect(() => {
    const account = JSON.parse(localStorage.getItem("account"));
    const BaseUrl = localStorage.getItem("api");

    if (account?.access_token) {
      setToken(account.access_token);
    } else {
      message.error("Missing authentication token. Please log in again.");
    }

    if (BaseUrl) {
      setBaseUrl(BaseUrl);
    } else {
      message.error("Missing base URL. Please configure your settings.");
    }
  }, []);

  const fetchReportDetails = async (reportId) => {
    if (!token || !baseUrl || !reportId) {
      setError("Missing authentication token, base URL, or report ID.");
      message.error("Missing authentication token, base URL, or report ID.");
      return;
    }

    setBusy(true);
    setError("");

    try {
      const url = `${baseUrl}/fastapi/distinct_report_type?unique_report_id=${reportId}`;
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const response = await axios.get(url, { headers });

      if (!response) {
        setError("No response received from the server.");
        message.error("No response received from the server.");
        setReportData([]);
        return;
      }

      if (!response.data) {
        setError("No record found.");
        message.info("No record found.");
        setReportData([]);
        return;
      }

      if (Array.isArray(response.data.data)) {
        setReportData(response.data.data);
      } else {
        setError("No record found.");
        message.info("No record found.");
        setReportData([]);
      }
    } catch (error) {
      console.error("Error fetching report details:", error);

      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unknown error occurred while fetching report details.";
      setError(`Error fetching report details: ${errorMessage}`);
      message.error(`Error fetching report details: ${errorMessage}`);
      setReportData([]);
    } finally {
      setBusy(false);
    }
  };

  const handleSubmit = async () => {
    if (!token || !baseUrl) {
      setError(
        "Missing authentication token or base URL. Please check your settings."
      );
      message.error(
        "Missing authentication token or base URL. Please check your settings."
      );
      return;
    }

    if (
      !reportAlias ||
      !dateRange ||
      dateRange.length !== 2 ||
      !noTranches ||
      !percentage ||
      !toFromSchoolMinTrips
    ) {
      setError("Please fill out all required fields.");
      message.error("Please fill out all required fields.");
      return;
    }

    setBusy(true);
    setError("");
    setSuccess("");
    setReportData([]);

    try {
      const formattedValues = {
        database_name: databaseName,
        report_alias: reportAlias,
        date_start: dateRange[0].toISOString(),
        date_end: dateRange[1].toISOString(),
        no_tranches: noTranches,
        percentage: percentage,
        to_school_min_trips: toSchoolMinTrips,
        to_school_min_days: toSchoolMinDays,
        from_school_min_trips: fromSchoolMinTrips,
        from_school_min_days: fromSchoolMinDays,
        to_from_school_min_trips: toFromSchoolMinTrips,
        to_from_school_min_days: toFromSchoolMinDays,
      };

      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const response = await axios.post(
        `${baseUrl}/fastapi/process_report`,
        formattedValues,
        { headers }
      );

      if (response.data && response.data.status === "success") {
        setSuccess("Reports generated successfully");
        message.success("Reports generated successfully");
        setCurrentReportId(response.data.message);
        fetchReportDetails(response.data.message);
      } else {
        setError(response.data?.message || "Failed to process report.");
        message.error(response.data?.message || "Failed to process report.");
      }
    } catch (error) {
      console.error("Error processing report:", error);
      const errorMessage = error.response?.data?.message || error.message;
      setError(`Error processing report: ${errorMessage}`);
      message.error(`Error processing report: ${errorMessage}`);
    } finally {
      setBusy(false);
    }
  };

  const validateAndSetValue = (value, setter, defaultValue = 1) => {
    const parsedValue = parseFloat(value);
    if (isNaN(parsedValue) || parsedValue < 0) {
      setter(defaultValue);
    } else {
      setter(parsedValue);
    }
  };

  const deleteReport = async (uniqueReportId) => {
    console.log("Deleting report with ID:", uniqueReportId);

    if (!token) {
      setError("Missing authentication token. Please log in again.");
      message.error("Missing authentication token. Please log in again.");
      return;
    }

    setBusy(true);
    try {
      const url = `${baseUrl}/fastapi/delete_report`;
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const payload = {
        unique_report_id: uniqueReportId,
      };

      const response = await axios.request({
        method: "delete",
        url,
        headers,
        data: payload,
      });

      if (response.data && response.data.detail) {
        if (response.data.detail.includes("No records found")) {
          message.warning(response.data.detail);
        } else {
          message.success(response.data.detail);
        }
      } else {
        message.success("Report deleted successfully.");
      }

      if (currentReportId) {
        fetchReportDetails(currentReportId);
      } else {
        setReportData([]);
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.detail ||
        error.response?.data?.message ||
        error.message;
      setError(`Error deleting report: ${errorMessage}`);
      message.error(`Error deleting report: ${errorMessage}`);
    } finally {
      setBusy(false);
    }
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedReport(null);
    setReportAlias("");
  };

  const updateReportAlias = async () => {
    if (!token || !selectedReport) {
      setError("Missing authentication token or selected report.");
      message.error("Missing authentication token or selected report.");
      return;
    }

    setBusy(true);
    try {
      const url = `${baseUrl}/fastapi/distinct_report_alias_update`;
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const payload = {
        database_name: "jga_school",
        unique_report_id: selectedReport.unique_report_id,
        report_alias: reportAlias,
      };

      await axios.patch(url, payload, { headers });
      message.success("Report name updated successfully.");

      // Refetch the report details after updating
      fetchReportDetails(selectedReport.unique_report_id);
      handleModalClose();
    } catch (error) {
      setError(
        `Error updating report name: ${
          error.response?.data?.message || error.message
        }`
      );
      message.error(
        `Error updating report name: ${
          error.response?.data?.message || error.message
        }`
      );
    } finally {
      setBusy(false);
    }
  };

  const openUpdateModal = (report) => {
    setSelectedReport(report);
    setReportAlias(report.report_alias);
    setIsModalVisible(true);
  };

  const renderActionsMenu = (record) => (
    <Menu>
      <Menu.Item
        key="view-provider-summary"
        onClick={() =>
          (window.location.hash = `/reports/provider-summary?uniqueReportId=${record.unique_report_id}`)
        }
      >
        View Provider Summary
      </Menu.Item>
      <Menu.Item
        key="view-vehicle-summary"
        onClick={() =>
          (window.location.hash = `/reports/vehicle-summary?uniqueReportId=${record.unique_report_id}`)
        }
      >
        View Vehicle Summary
      </Menu.Item>
      <Menu.Item
        key="view-vehicle-report"
        onClick={() =>
          (window.location.hash = `/reports/vehicle-report?uniqueReportId=${record.unique_report_id}`)
        }
      >
        View Vehicle Report
      </Menu.Item>
      <Menu.Item
        key="view-student-report"
        onClick={() =>
          (window.location.hash = `/reports/student-report?uniqueReportId=${record.unique_report_id}`)
        }
      >
        View Student Report
      </Menu.Item>

      <Menu.Item key="update" onClick={() => openUpdateModal(record)}>
        Update Name
      </Menu.Item>
      <Menu.Item
        key="delete"
        danger
        onClick={() => deleteReport(record.unique_report_id)}
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  const tableColumns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Unique Report ID",
      dataIndex: "unique_report_id",
      key: "unique_report_id",
    },

    {
      title: "Report Name",
      dataIndex: "report_alias",
      key: "report_alias",
    },
    {
      title: "Start Date",
      dataIndex: "date_start",
      key: "date_start",
      render: (date) => moment(date).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "End Date",
      dataIndex: "date_end",
      key: "date_end",
      render: (date) => moment(date).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "No. of Tranches",
      dataIndex: "no_tranches",
      key: "no_tranches",
    },
    {
      title: "To_From_School_Min_Trips",
      dataIndex: "to_from_school_min_trips",
      key: "to_from_school_min_trips",
    },
    {
      title: "Percentage",
      dataIndex: "percentage",
      key: "percentage",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Actions",
      key: "actions",
      width: 100,
      render: (text, record) => (
        <Dropdown overlay={renderActionsMenu(record)} trigger={["click"]}>
          <span style={{ cursor: "pointer" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
              width="25"
              height="25"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
              />
            </svg>
          </span>
        </Dropdown>
      ),
    },
  ];

  return (
    <div style={{ marginTop: 20, padding: "0 20px" }}>
      <h4 style={{ textAlign: "center", marginBottom: 10 }}>
        Billing Report Processing
      </h4>

      {error && (
        <Alert
          message={error}
          type="error"
          showIcon
          style={{ marginBottom: 8 }}
        />
      )}

      {success && (
        <div
          style={{ display: "flex", justifyContent: "center", marginBottom: 8 }}
        >
          <Alert
            message="Reports generated successfully"
            type="success"
            showIcon
            style={{ width: "fit-content", textAlign: "center" }}
          />
        </div>
      )}

      <Row gutter={[8, 4]} style={{ maxWidth: 800, margin: "0 auto" }}>
        <Col span={12}>
          <div style={{ marginBottom: 8 }}>
            <label>Report Name</label>
            <Input
              placeholder="Enter Report Name"
              value={reportAlias}
              onChange={(e) => setReportAlias(e.target.value)}
            />
          </div>
        </Col>

        <Col span={12}>
          <div style={{ marginBottom: 8 }}>
            <label>Number of Tranches</label>
            <Input
              type="number"
              placeholder="Enter Number of Tranches"
              value={noTranches}
              onChange={(e) =>
                validateAndSetValue(e.target.value, setNoTranches)
              }
              min={1}
            />
          </div>
        </Col>

        <Col span={24}>
          <div style={{ marginBottom: 8 }}>
            <label>Date Range</label>
            <RangePicker
              showTime={{ format: "HH:mm" }}
              format="YYYY-MM-DD HH:mm"
              style={{ width: "100%" }}
              value={dateRange}
              onChange={(dates) => setDateRange(dates)}
            />
          </div>
        </Col>

        <Col span={12}>
          <div style={{ marginBottom: 8 }}>
            <label>Percentage (%)</label>
            <Input
              type="number"
              step="0.01"
              placeholder="Enter Percentage"
              value={percentage}
              onChange={(e) =>
                validateAndSetValue(e.target.value, setPercentage)
              }
              min={0}
            />
          </div>
        </Col>

        <Col span={12}>
          <div style={{ marginBottom: 8 }}>
            <label>To/From School Min Trips</label>
            <Input
              type="number"
              placeholder="Enter Min Trips to/from School"
              value={toFromSchoolMinTrips}
              onChange={(e) =>
                validateAndSetValue(e.target.value, setToFromSchoolMinTrips)
              }
              min={1}
            />
          </div>
        </Col>

        <Col span={24}>
          <Button
            type="primary"
            onClick={handleSubmit}
            loading={busy}
            disabled={!token || busy}
            style={{ width: "100%" }}
          >
            Process Report
          </Button>
        </Col>

        <Col span={24}>
          <a href="/#/reports/distinct-reports">
            <Button
              type="default"
              // onClick={() => setShowDistinctReports(true)}
              style={{ width: "100%", marginTop: 16 }}
            >
              View Other Previously Generated Reports
            </Button>
          </a>
        </Col>
      </Row>

      <Spin spinning={busy}>
        {reportData.length > 0 ? (
          <div
            style={{
              width: "90%",
              margin: "auto",
              marginTop: "40px",
              overflowX: "auto",
            }}
          >
            {/* Data Table */}
            <Table
              columns={tableColumns}
              dataSource={Array.isArray(reportData) ? reportData : []}
              rowKey="unique_report_id"
              bordered
            />
          </div>
        ) : (
          <div style={{ textAlign: "center", marginTop: 40 }}>
            <Empty
              description="No data available. Please generate a report."
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </div>
        )}
      </Spin>

      <Modal
        title="Update Report Name"
        visible={isModalVisible}
        onCancel={handleModalClose}
        onOk={updateReportAlias}
        confirmLoading={busy}
      >
        <Form layout="vertical">
          <Form.Item label="Report Name">
            <Input
              value={reportAlias}
              onChange={(e) => setReportAlias(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Previously Generated Reports"
        visible={showDistinctReports}
        onCancel={() => setShowDistinctReports(false)}
        footer={null}
        width="80%"
      >
        <DistinctReports currentReportId={currentReportId} />
      </Modal>
    </div>
  );
};

export default ReportProcessing;
