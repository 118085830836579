import React, { Component } from "react";
import { subscribe } from "../../utils/bunifu-redux";
import { Card, Col, Icon, Layout, Modal, Row, Tabs } from "antd";
import moment from "moment";
import { get, put } from "../../utils/Storage";
import PromotionWizard from "./PromotionWizard";
import { Route } from "../../utils/Routing";
import auth from "../../Components/Auth";

class Tools extends Component {
  state = {
    name: "Tools",
    date: moment().format("YYYY-MM-DD"),
    promotion: false,
  };

  componentWillMount() {
    subscribe(this);
  }

  LoadData() {
    this.state.loading = false;
    this.forceUpdate();
  }

  render() {
    return (
      <Layout.Content>
        {/*promotion Popup*/}
        <Modal
          footer={[]}
          maskClosable={false}
          title={"Promotion Wizard"}
          width={1000}
          onCancel={() => this.setState({ promotion: false })}
          visible={this.state.promotion}
        >
          <PromotionWizard
            onCancel={() => this.setState({ promotion: false })}
          />
        </Modal>

        <Card
          bordered={false}
          loading={this.state.loading}
          style={{ marginTop: -15 }}
        >
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane
              tab={
                <span>
                  <Icon type="file-text" />
                  Automation Tools
                </span>
              }
              key="1"
            >
              <Row>
                <Col hidden={auth.noView(auth.AccessPromotionWizard)} span={3}>
                  <div
                    align="center"
                    onClick={() => this.setState({ promotion: true })}
                    onMouseOver={(e) => (e.target.style.background = "#93D8B5")}
                    onMouseOut={(e) => (e.target.style.background = "#F0F2F5")}
                    style={{
                      borderRadius: "5px",
                      width: "90%",
                      cursor: "pointer",
                      backgroundColor: "#F0F2F5",
                    }}
                  >
                    <br />
                    <Icon height={250} type="setting" />
                    <br />
                    Promotion Wizard
                    <br />
                    <br />
                  </div>
                </Col>
                <Col hidden={auth.noView(auth.AccessImportWizard)} span={3}>
                  <div
                    align="center"
                    onClick={() => Route("import")}
                    onMouseOver={(e) => (e.target.style.background = "#93D8B5")}
                    onMouseOut={(e) => (e.target.style.background = "#F0F2F5")}
                    style={{
                      borderRadius: "5px",
                      width: "90%",
                      cursor: "pointer",
                      backgroundColor: "#F0F2F5",
                    }}
                  >
                    <br />
                    <Icon height={250} type="file-excel" />
                    <br />
                    Import Wizard
                    <br /> <br />
                  </div>
                </Col>
                <Col span={3}>
                  <div
                    align="center"
                    onClick={() => {
                      // reset data
                      let admin = get("admin");
                      let version = get("version");
                      localStorage.clear();
                      put("admin", admin);
                      put("version", version);
                      window.location.replace(
                        window.location.pathname +
                          window.location.search +
                          window.location.hash
                      );
                    }}
                    onMouseOver={(e) => (e.target.style.background = "#93D8B5")}
                    onMouseOut={(e) => (e.target.style.background = "#F0F2F5")}
                    style={{
                      borderRadius: "5px",
                      width: "90%",
                      cursor: "pointer",
                      backgroundColor: "#F0F2F5",
                    }}
                  >
                    <br />
                    <Icon height={250} type="sync" />
                    <br />
                    <br />
                    Refresh System
                    <br /> <br />
                  </div>
                </Col>
                <Col span={3}>
                  <a
                    href="/#/add-types"
                    style={{ textDecoration: "none", color: "#595959" }}
                  >
                    <div
                      align="center"
                      onMouseOver={(e) =>
                        (e.target.style.background = "#93D8B5")
                      }
                      onMouseOut={(e) =>
                        (e.target.style.background = "#F0F2F5")
                      }
                      style={{
                        borderRadius: "5px",
                        width: "90%",
                        cursor: "pointer",
                        backgroundColor: "#F0F2F5",
                      }}
                    >
                      <br />
                      <Icon
                        height={250}
                        type="plus-circle"
                        style={{ color: "black" }}
                      />{" "}
                      <br />
                      <br />
                      Add Types
                      <br /> <br />
                    </div>
                  </a>
                </Col>
              </Row>
            </Tabs.TabPane>
          </Tabs>
          <br />
          <br />
          {get("admin").role === "Administrator" && (
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane
                tab={
                  <span>
                    <Icon type="file-text" />
                    Plugins
                  </span>
                }
                key="1"
              >
                <Row>
                  <Col hidden={auth.noView(auth.AccessAuditReport)} span={3}>
                    <div
                      align="center"
                      onClick={() => window.openTab("#?report=audit")}
                      onMouseOver={(e) =>
                        (e.target.style.background = "#93D8B5")
                      }
                      onMouseOut={(e) =>
                        (e.target.style.background = "#F0F2F5")
                      }
                      style={{
                        borderRadius: "5px",
                        width: "90%",
                        cursor: "pointer",
                        backgroundColor: "#F0F2F5",
                      }}
                    >
                      <br />
                      <Icon height={250} type="security-scan" />
                      <br />
                      Audit Trail report
                      <br /> <br />
                    </div>
                  </Col>
                  <Col hidden={auth.noView(auth.AccessDataBrowser)} span={3}>
                    <div
                      align="center"
                      onClick={() => window.openTab("#?report=moing")}
                      onMouseOver={(e) =>
                        (e.target.style.background = "#93D8B5")
                      }
                      onMouseOut={(e) =>
                        (e.target.style.background = "#F0F2F5")
                      }
                      style={{
                        borderRadius: "5px",
                        width: "90%",
                        cursor: "pointer",
                        backgroundColor: "#F0F2F5",
                      }}
                    >
                      <br />
                      <Icon height={250} type="database" />
                      <br />
                      Data Browser
                      <br /> <br />
                    </div>
                  </Col>
                  <Col hidden={auth.noView(auth.AccessDataBrowser)} span={3}>
                    <div
                      align="center"
                      onClick={() =>
                        window.openTab("plugins/onboarding-wizard/wwwroot/")
                      }
                      onMouseOver={(e) =>
                        (e.target.style.background = "#93D8B5")
                      }
                      onMouseOut={(e) =>
                        (e.target.style.background = "#F0F2F5")
                      }
                      style={{
                        borderRadius: "5px",
                        width: "90%",
                        cursor: "pointer",
                        backgroundColor: "#F0F2F5",
                      }}
                    >
                      <br />
                      <Icon height={250} type="database" />
                      <br />
                      Onboading Wizard
                      <br /> <br />
                    </div>
                  </Col>
                </Row>
              </Tabs.TabPane>
            </Tabs>
          )}
        </Card>
      </Layout.Content>
    );
  }
}

export default Tools;
