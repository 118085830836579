import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import {
  Table,
  Button,
  Alert,
  Select,
  Empty,
  Spin,
  Col,
  Row,
  message,
} from "antd";
import {
  exportToExcel,
  exportToCSV,
  exportToPDF,
} from "../../utils/generateReports";
import {
  FileExcelOutlined,
  FileTextOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import { fetchReportFilters } from "../../utils/apiUtils";
import { debounce } from "lodash";
import { useLocation } from "react-router-dom";
import "antd/dist/antd.css";

const { Option } = Select;

const ProviderSummary = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const hash = location.hash;

  const hashParams = new URLSearchParams(hash.split("?")[1]);
  const [busy, setBusy] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [results, setResults] = useState([]);
  const [error, setError] = useState("");
  const [filters, setFilters] = useState(null);

  const [token, setToken] = useState("");
  const [baseUrl, setBaseUrl] = useState("");
  const [provider, setProvider] = useState("");
  const [uniqueReportId, setUniqueReportId] = useState("");
  const [providers, setProviders] = useState([]);
  const [text, setText] = useState("Filter Report");
  const [originalProviders, setOriginalProviders] = useState([]);
  const [filteredProviders, setFilteredProviders] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalRecords, setTotalRecords] = useState(0);
  const [lastPage, setLastPage] = useState(1);

  const [uniqueReportIds, setUniqueReportIds] = useState([]);
  const [filteredUniqueReportIds, setFilteredUniqueReportIds] = useState([]);

  // const uniqueReportId = hashParams.get("uniqueReportId");
  const reportName = hashParams.get("reportName");
  const startDate = hashParams.get("startDate");
  const endDate = hashParams.get("endDate");
  const noTranches = hashParams.get("noTranches");
  const minTrips = hashParams.get("minTrips");
  const percentage = hashParams.get("percentage");

  // Log the extracted values
  useEffect(() => {
    console.log("Extracted Query Parameters:");
    console.log("uniqueReportId:", uniqueReportId);
    console.log("reportName:", reportName);
    console.log("startDate:", startDate);
    console.log("endDate:", endDate);
    console.log("noTranches:", noTranches);
    console.log("minTrips:", minTrips);
    console.log("percentage:", percentage);
  }, [
    uniqueReportId,
    reportName,
    startDate,
    endDate,
    noTranches,
    minTrips,
    percentage,
  ]);

  useEffect(() => {
    let uniqueReportId = null;

    if (window.location.hash.includes("?")) {
      const queryParams = new URLSearchParams(
        window.location.hash.split("?")[1]
      );
      uniqueReportId = queryParams.get("uniqueReportId");
    }
    if (uniqueReportId) {
      setUniqueReportId(uniqueReportId);
    } else {
      console.warn("⚠️ No Unique Report ID found in URL.");
    }

    try {
    } catch (error) {}

    console.log("Extracted Unique Report ID:", uniqueReportId);
    const account = JSON.parse(localStorage.getItem("account"));
    const BaseUrl = localStorage.getItem("api");

    if (account?.access_token) {
      setToken(account.access_token);
    } else {
      message.error("Missing authentication token. Please log in again.");
    }

    if (BaseUrl) {
      setBaseUrl(BaseUrl);
    } else {
      message.error("Missing base URL. Please configure your settings.");
    }
  }, []);

  useEffect(() => {
    const loadFilters = async () => {
      if (uniqueReportId && baseUrl && token) {
        try {
          const filters = await fetchReportFilters(
            baseUrl,
            token,
            uniqueReportId
          );
          if (filters) {
            setFilters(filters);
            setOriginalProviders(filters.providers || []);
            setFilteredProviders(filters.providers || []);
          }
        } catch (error) {
          console.error("Error fetching filters:", error);
          message.error("Failed to fetch filters.");
        }
      }
    };

    loadFilters();
  }, [uniqueReportId, baseUrl, token]);

  const handleFilterSearch = (value, filterType) => {
    const filterMap = {
      provider: {
        data: originalProviders,
        filterFn: (item) =>
          item.provider.toLowerCase().includes(value.toLowerCase()),
        setter: setFilteredProviders,
      },
    };

    const { data, filterFn, setter } = filterMap[filterType] || {};
    if (!data) return;

    if (!value) {
      setter(data);
    } else {
      const filtered = data.filter(filterFn);
      setter(filtered);
    }
  };

  useEffect(() => {
    const loadFilters = async () => {
      if (uniqueReportId && baseUrl && token) {
        const filters = await fetchReportFilters(
          baseUrl,
          token,
          uniqueReportId
        );
        if (filters) {
          setFilters(filters);
        }
      }
    };

    loadFilters();
  }, [uniqueReportId, baseUrl, token]);

  useEffect(() => {
    if (token && baseUrl) {
      generateReport();
    }
  }, [token, baseUrl]);

  const debouncedSearch = debounce(handleFilterSearch, 300);

  const generateReport = async (page = currentPage, limit = pageSize) => {
    if (!token) {
      setError("Missing authentication token. Please log in again.");
      message.error("Missing authentication token. Please log in again.");
      return;
    }

    setBusy(true);
    setText("Filtering Report...");
    setError("");

    try {
      const url = `${baseUrl}/fastapi/provider/summary`;
      const params = {
        unique_report_id: uniqueReportId,
        provider_id: provider,
        start_date: startDate,
        end_date: endDate,
        page,
        per_page: limit,
      };

      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const { data } = await axios.get(url, { params, headers });

      if (!data || !data.data || data.data.length === 0) {
        setError("No records found matching the filter criteria.");
        message.info("No records found matching the filter criteria.");
        setResults([]);
        return;
      }

      // Calculate totals using aggregates
      const totalRow = calculateTotals(data.aggregates);

      // Append the totals row to the results
      setResults([...data.data, totalRow]);

      setCurrentPage(data.pagination?.current_page || 1);
      setPageSize(data.pagination?.per_page || limit);
      setTotalRecords(data.pagination?.total || 0);
      setLastPage(data.pagination?.last_page || 1);

      const uniqueProviders = [
        ...new Map(
          data.data.map((item) => [item.provider_id, item.provider])
        ).values(),
      ].map((provider, index) => ({
        id: index + 1,
        provider_id: data.data.find((item) => item.provider === provider)
          .provider_id,
        provider,
      }));

      setProviders(uniqueProviders);

      const uniqueIds = [
        ...new Set(data.data.map((item) => item.unique_report_id)),
      ];
      setUniqueReportIds(uniqueIds);
      setFilteredUniqueReportIds(uniqueIds);
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      setError(`Error fetching data: ${errorMessage}`);
      message.error(`Error fetching data: ${errorMessage}`);
    } finally {
      setBusy(false);
      setText("Filter Report");
    }
  };

  const calculateTotals = (aggregates) => {
    if (!aggregates) return null;

    return {
      provider: "Totals",
      number_of_vehicles: aggregates.number_of_vehicles || 0,
      number_of_students: aggregates.number_of_students || 0,
      total_trips_count: aggregates.total_trips_count || 0,

      perVehicleCost_BasedOnTrips: aggregates.perVehicleCost_BasedOnTrips || 0,
      Based_On_Trips_Percantage: aggregates.Based_On_Trips_Percantage || 0,
      Based_On_Trips_Sum_less_Percentage:
        aggregates.Based_On_Trips_Sum_less_Percentage || 0,

      perVehicleCost_BasedOnSchedules:
        aggregates.perVehicleCost_BasedOnSchedules || 0,
      Based_On_Schedules_Percentage:
        aggregates.Based_On_Schedules_Percentage || 0,
      Based_On_Schedules_Sum_less_Percentage:
        aggregates.Based_On_Schedules_Sum_less_Percentage || 0,
    };
  };

  const fetchAllData = async () => {
    setExporting(true);
    let allData = [];
    let totalRow = null;

    try {
      for (let page = 1; page <= lastPage; page++) {
        const { data } = await axios.get(
          `${baseUrl}/fastapi/provider/summary`,
          {
            params: {
              unique_report_id: uniqueReportId,
              provider_id: provider,
              page,
              per_page: pageSize,
            },
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (data?.data?.length) {
          allData = allData.concat(data.data);
        }

        if (data.aggregates && !totalRow) {
          totalRow = calculateTotals(data.aggregates);
        }
      }

      if (totalRow) {
        allData.push(totalRow);
      }

      return allData;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      setError(`Error fetching all pages: ${errorMessage}`);
      message.error(`Error fetching all pages: ${errorMessage}`);
      return null;
    } finally {
      setExporting(false);
    }
  };

  const getExportData = (data, isAllPages = false) => {
    return data.map((item, index) => ({
      serialNumber: isAllPages
        ? index + 1
        : (currentPage - 1) * pageSize + index + 1,
      ...item,
    }));
  };

  const handleExport = async (exportFn, format, isAllPages = false) => {
    const data = isAllPages ? await fetchAllData() : results;
    if (data?.length) {
      const augmentedData = getExportData(data, isAllPages);
      exportFn(
        augmentedData,
        `ProviderSummary_${isAllPages ? "AllPages" : "CurrentPage"}`
      );
      message.success(`Exported successfully as ${format}.`);
    } else {
      message.warning("No data available to export.");
    }
  };

  const exportAllPagesExcel = async () => {
    const allData = await fetchAllData();
    if (allData && allData.length > 0) {
      const augmentedData = getExportData(allData, true);
      exportToExcel(augmentedData, "ProviderSummary_AllPages");
      message.success("All pages exported successfully as Excel.");
    } else {
      message.warning("No data available to export.");
    }
  };

  const exportAllPagesCSV = async () => {
    const allData = await fetchAllData();
    if (allData && allData.length > 0) {
      const augmentedData = getExportData(allData, true);
      exportToCSV(augmentedData, "ProviderSummary_AllPages");
      message.success("All pages exported successfully as CSV.");
    } else {
      message.warning("No data available to export.");
    }
  };

  const exportAllPagesPDF = async () => {
    const allData = await fetchAllData();
    if (allData && allData.length > 0) {
      const augmentedData = getExportData(allData, true);
      exportToPDF(
        columns,
        augmentedData,
        "ProviderSummary_AllPages",
        "Provider Summary Report (All Pages)",
        [0, 175, 80],
        "/img/go-green.png",
        metadata
      );
      message.success("All pages exported successfully as PDF.");
    } else {
      message.warning("No data available to export.");
    }
  };

  const metadata = {
    reportName,
    startDate,
    endDate,
    noTranches,
    minTrips,
    percentage,
  };

  const columns = [
    { title: "Provider", dataIndex: "provider", key: "provider" },
    {
      title: "Number of Vehicles",
      dataIndex: "number_of_vehicles",
      key: "number_of_vehicles",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : text),
    },
    {
      title: "Number of Students",
      dataIndex: "number_of_students",
      key: "number_of_students",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : text),
    },
    {
      title: "Total Trips Count",
      dataIndex: "total_trips_count",
      key: "total_trips_count",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : text),
    },
    {
      title: "Per Vehicle Cost (Trips)",
      dataIndex: "perVehicleCost_BasedOnTrips",
      key: "perVehicleCost_BasedOnTrips",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : text),
    },
    {
      title: "Based on Trips Percentage",
      dataIndex: "Based_On_Trips_Percantage",
      key: "Based_On_Trips_Percantage",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : text),
    },
    {
      title: "Based on Trips Sum (Less Percentage)",
      dataIndex: "Based_On_Trips_Sum_less_Percentage",
      key: "Based_On_Trips_Sum_less_Percentage",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : text),
    },
    // {
    //   title: "Per Vehicle Cost (Schedules)",
    //   dataIndex: "perVehicleCost_BasedOnSchedules",
    //   key: "perVehicleCost_BasedOnSchedules",
    //   render: (text) => (Number.isFinite(text) ? text.toLocaleString() : text),
    // },
    // {
    //   title: "Based on Schedules Percentage",
    //   dataIndex: "Based_On_Schedules_Percentage",
    //   key: "Based_On_Schedules_Percentage",
    //   render: (text) => (Number.isFinite(text) ? text.toLocaleString() : text),
    // },
    // {
    //   title: "Based on Schedules Sum (Less Percentage)",
    //   dataIndex: "Based_On_Schedules_Sum_less_Percentage",
    //   key: "Based_On_Schedules_Sum_less_Percentage",
    //   render: (text) => (Number.isFinite(text) ? text.toLocaleString() : text),
    // },
  ];

  return (
    <div style={{ marginTop: 30 }}>
      <h4 style={{ textAlign: "center", marginBottom: 20 }}>
        Provider Summary
      </h4>
      <div style={{ width: "50%", margin: "0 auto", marginTop: 30 }}>
        <Row gutter={[16, 16]} style={{ marginBottom: "40px" }}>
          <Col span={12}>
            <label>Provider</label>
            <Select
              placeholder="Select Provider"
              value={provider}
              onChange={setProvider}
              onSearch={(value) => debouncedSearch(value, "provider")}
              showSearch
              filterOption={false}
              style={{ width: "100%" }}
            >
              {filteredProviders.map((provider) => (
                <Option key={provider.provider_id} value={provider.provider_id}>
                  {provider.provider}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={12}>
            <Button
              type="default"
              onClick={() => setProvider("")}
              style={{ width: "100%", marginTop: "26px" }}
            >
              Clear Filters
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type="primary"
              onClick={() => generateReport(1, pageSize)}
              loading={busy}
              style={{ width: "100%", marginTop: "26px" }}
            >
              Filter Report
            </Button>
          </Col>
        </Row>
      </div>
      <hr />
      {error && <Alert message={error} type="error" showIcon />}
      {/* Report Metadata Header */}
      {results.length > 0 && (
        <div
          style={{
            backgroundColor: "#ffffff",
            padding: "20px",
            borderRadius: "8px",
            marginBottom: "20px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            border: "1px solid #e8e8e8",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* Report Details Heading */}
          <h3
            style={{
              textAlign: "center",
              marginBottom: "20px",
              fontSize: "18px",
              fontWeight: "600",
              color: "#00AF50",
            }}
          >
            Report Details
          </h3>

          {/* Metadata Fields */}
          <Row
            gutter={[64, 16]}
            style={{
              width: "100%",
              display: "flex",
              flexWrap: "nowrap",
              justifyContent: "center",
            }}
          >
            {/* Unique Report ID */}
            <Col>
              <div
                style={{
                  display: "flex",
                  fontSize: "15px",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <strong style={{ color: "#595959" }}>Unique Report ID:</strong>
                <span style={{ color: "#262626", fontWeight: "500" }}>
                  {results[0]?.unique_report_id || "N/A"}
                </span>
              </div>
            </Col>

            {/* Report Name */}
            <Col>
              <div
                style={{
                  display: "flex",
                  fontSize: "15px",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <strong style={{ color: "#595959" }}>Report Name:</strong>
                <span style={{ color: "#262626", fontWeight: "500" }}>
                  {reportName || "N/A"}
                </span>
              </div>
            </Col>

            {/* Start Date */}
            <Col>
              <div
                style={{
                  display: "flex",
                  fontSize: "15px",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <strong style={{ color: "#595959" }}>Start Date:</strong>
                <span style={{ color: "#262626", fontWeight: "500" }}>
                  {startDate ? moment(startDate).format("YYYY-MM-DD") : "N/A"}
                </span>
              </div>
            </Col>

            {/* End Date */}
            <Col>
              <div
                style={{
                  display: "flex",
                  fontSize: "15px",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <strong style={{ color: "#595959" }}>End Date:</strong>
                <span style={{ color: "#262626", fontWeight: "500" }}>
                  {endDate ? moment(endDate).format("YYYY-MM-DD") : "N/A"}
                </span>
              </div>
            </Col>

            {/* No. of Tranches */}
            <Col>
              <div
                style={{
                  display: "flex",
                  fontSize: "15px",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <strong style={{ color: "#595959" }}>No. of Tranches:</strong>
                <span style={{ color: "#262626", fontWeight: "500" }}>
                  {noTranches || "N/A"}
                </span>
              </div>
            </Col>

            {/* Minimum Trips */}
            <Col>
              <div
                style={{
                  display: "flex",
                  fontSize: "15px",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <strong style={{ color: "#595959" }}>Minimum Trips:</strong>
                <span style={{ color: "#262626", fontWeight: "500" }}>
                  {minTrips || "N/A"}
                </span>
              </div>
            </Col>

            {/* Percentage */}
            <Col>
              <div
                style={{
                  display: "flex",
                  fontSize: "15px",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <strong style={{ color: "#595959" }}>Percentage:</strong>
                <span style={{ color: "#262626", fontWeight: "500" }}>
                  {percentage ? `${percentage}%` : "N/A"}
                </span>
              </div>
            </Col>
          </Row>
        </div>
      )}
      <Spin spinning={busy || exporting}>
        {results.length > 0 ? (
          <div
            style={{
              width: "90%",
              margin: "auto",
              marginTop: "40px",
              overflowX: "auto",
            }}
          >
            <Row justify="end" style={{ marginBottom: 20 }}>
              <Col>
                <Button
                  type="primary"
                  onClick={() =>
                    exportToExcel(results, "ProviderSummary_CurrentPage")
                  }
                  style={{ marginRight: 10 }}
                  disabled={exporting}
                >
                  <FileExcelOutlined style={{ marginRight: 5 }} /> Export
                  Current Pages (Excel)
                </Button>
                <Button
                  type="primary"
                  onClick={() =>
                    exportToCSV(results, "ProviderSummary_CurrentPage")
                  }
                  style={{ marginRight: 10 }}
                  disabled={exporting}
                >
                  <FileTextOutlined style={{ marginRight: 5 }} /> Export Current
                  Pages (CSV)
                </Button>
                <Button
                  type="primary"
                  onClick={() =>
                    exportToPDF(
                      columns,
                      results,
                      "ProviderSummary_CurrentPage",
                      "Provider Summary Report (Current Page)",
                      [0, 175, 80],
                      "/img/go-green.png",
                      metadata
                    )
                  }
                  style={{ marginRight: 10 }}
                  disabled={exporting}
                >
                  <FilePdfOutlined style={{ marginRight: 5 }} /> Export Current
                  Pages (PDF)
                </Button>
                <Button
                  type="default"
                  onClick={exportAllPagesExcel}
                  style={{ marginRight: 10 }}
                  disabled={exporting}
                >
                  <FileExcelOutlined style={{ marginRight: 5 }} /> Export All
                  Pages (Excel)
                </Button>
                <Button
                  type="default"
                  onClick={exportAllPagesCSV}
                  style={{ marginRight: 10 }}
                  disabled={exporting}
                >
                  <FileTextOutlined style={{ marginRight: 5 }} /> Export All
                  Pages (CSV)
                </Button>
                <Button
                  type="default"
                  onClick={exportAllPagesPDF}
                  disabled={exporting}
                >
                  <FilePdfOutlined style={{ marginRight: 5 }} /> Export All
                  Pages (PDF)
                </Button>
              </Col>
            </Row>
            <Table
              columns={columns}
              dataSource={results}
              pagination={{
                current: currentPage,
                pageSize,
                total: totalRecords,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "50", "100"],
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                onChange: (page, size) => {
                  setCurrentPage(page);
                  setPageSize(size);
                  generateReport(page, size);
                },
                onShowSizeChange: (current, size) => {
                  setCurrentPage(1);
                  setPageSize(size);
                  generateReport(1, size);
                },
              }}
              rowKey="id"
            />
          </div>
        ) : (
          <div style={{ textAlign: "center", marginTop: 40 }}>
            <Empty
              description="No data available. Please generate a report."
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </div>
        )}
      </Spin>
    </div>
  );
};

export default ProviderSummary;
