import axios from "axios";
import { message } from "antd";

export const fetchReportFilters = async (baseUrl, token, uniqueReportId) => {
  if (!baseUrl || !token || !uniqueReportId) {
    console.warn("⚠️ Missing required parameters for fetching report filters.");
    return null;
  }

  try {
    const response = await axios.get(`${baseUrl}/fastapi/report/filters`, {
      params: { unique_report_id: uniqueReportId },
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    console.log("Report Filters Fetched:", response.data);
    return response.data;
  } catch (error) {
    console.error(" Error fetching filters:", error);
    message.error("Failed to fetch report filters.");
    return null;
  }
};
