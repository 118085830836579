import React, { Component } from "react";
import { subscribe } from "../../utils/bunifu-redux";
import {
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Icon,
  Input,
  Layout,
  List,
  message,
  Popconfirm,
  Popover,
  Progress,
  Radio,
  Row,
  Select,
  Table,
  Tabs,
  Tag,
  Timeline,
} from "antd";
import axios from "axios";
import Axios from "axios";
import moment from "moment";
import { get, keyValue, select } from "../../utils/Storage";
import Message from "./Message";
import { Params } from "../../utils/Routing";
import auth from "../../Components/Auth";
import goAPI from "../../Server/Go";
import _ from "linq";

class Trips extends Component {
  state = {
    name: "Dashboard",
    date: moment().format("YYYY-MM-DD"),
    page: "0",
    data: [],
    loading: true,
    prevMonth: moment().format("MM"),
    showTripInfo: false,
    trip: null,
    search: "",
    direction: "",
    route: "",
    schedules: keyValue(get("schedules")),
    vehicles: keyValue(get("vehicles")),
    users: keyValue(get("users")),
    provider: "",
  };

  clearTripData() {
    localStorage.removeItem("tripData");
    localStorage.removeItem("tripStudents");
    localStorage.removeItem("curTrip");
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.showTripInfo !== this.state.showTripInfo &&
      !this.state.showTripInfo
    ) {
      // Clear trip data when showTripInfo is false
      this.clearTripData();
    }
  }

  componentWillMount() {
    subscribe(this);
    if (this.props.provider) this.state.provider = this.props.provider.Name;
  }

  componentDidMount() {
    if (Params().id) this.state.page = Params().id.toString();
    if (Params().direction)
      this.state.direction = Params().direction.toString();
    if (Params().date) this.state.date = Params().date.toString();

    this.LoadData();
  }

  ApplyFilters(arr) {
    if (goAPI.getUser().isAdmin()) return arr;

    let data = _.from(arr).where((trip) => {
      const providerVehicles = goAPI.getUser().getProvider().getVehicles();
      if (!providerVehicles.any()) return false;

      const schedule = select("schedules", trip.ScheduleId);

      if (!schedule) return false;

      return providerVehicles.any((r) => r.id == schedule.VehicleId);
    });
    return data.toArray();
  }

  LoadData() {
    // Make a request for a user with a given ID
    this.setState({ loading: true });
    axios
      .get("/trips?Date=" + this.state.date)
      .then((response) => {
        this.setState({
          data: this.ApplyFilters(response.data),
          loading: false,
        });
      })
      .catch((error) => {
        message.error(error.message);
        this.setState({ loading: false });
      });
  }

  render() {
    message.destroy();
    message.loading("Please wait...");

    // if (get('admin').role == "Transporter") {
    //     var pid = get('admin').user.providerid;
    //     this.state.provider = keyValue(get("providers"))[pid].Name;
    // }

    if (
      this.state.prevMonth != moment(this.state.date, "YYYY-MM-DD").format("MM")
    ) {
      this.state.prevMonth = moment(this.state.date, "YYYY-MM-DD").format("MM");
      this.LoadData();
    }

    var data = {};

    data.labels = [];
    data.datasets = [];

    var dataset1 = {};
    dataset1.data = [];
    dataset1.label = "To School Trips";
    dataset1.backgroundColor = "#94CCFF";

    var dataset2 = {};
    dataset2.data = [];
    dataset2.label = "From School Trips";
    dataset2.backgroundColor = "#71BDC3";

    for (let index = 1; index <= 31; index++) {
      data.labels.push(index.toString());
      dataset1.data.push(Math.floor(Math.random() * 20));
      dataset2.data.push(Math.floor(Math.random() * 20));
    }

    data.datasets.push(dataset1);
    data.datasets.push(dataset2);

    let records = this.state.data;

    records = records.filter((trip) => {
      let schedule = this.state.schedules[trip.ScheduleId];
      let vehicle = this.state.vehicles[schedule.VehicleId];

      //check vahilce
      if (this.props.provider)
        return vehicle.ProviderId === this.props.provider.id;

      return true;
    });

    records = records.filter((trip) => {
      if (!trip) return false;

      let schedule = this.state.schedules[trip.ScheduleId] || false;
      let vehicle = this.state.vehicles[schedule.VehicleId] || false;
      let driver = this.state.users[schedule.DriverId] || false;
      let agent = this.state.users[schedule.AttendantId] || false;

      if (
        schedule &&
        schedule.Name.toLowerCase().includes(
          this.state.search.toLowerCase().trim()
        )
      ) {
        return true;
      }

      if (
        vehicle &&
        vehicle.PlateNo.toLowerCase().includes(
          this.state.search.toLowerCase().trim()
        )
      ) {
        return true;
      }

      if (
        driver &&
        driver.name
          .toLowerCase()
          .includes(this.state.search.toLowerCase().trim())
      ) {
        return true;
      }

      if (
        agent &&
        agent.name
          .toLowerCase()
          .includes(this.state.search.toLowerCase().trim())
      ) {
        return true;
      }

      return false;
    });

    records = records.filter((trip) =>
      trip.Direction.includes(this.state.direction)
    );
    records = records.filter((trip) =>
      trip.RouteId.toString().includes(this.state.route)
    );

    const filterForm = (
      <Row>
        <Col span={6}>
          <Input.Search
            style={{ width: "98%" }}
            placeholder="Schedule/Vehicle/Driver/Agent"
            value={this.state.search}
            onSearch={(e) => this.setState({ search: e })}
          />
        </Col>

        <Col span={6}>
          <Select
            disabled={get("admin").role !== "Administrator"}
            value={this.state.provider}
            style={{ width: "98%" }}
            onChange={(val) => this.setState({ provider: val })}
          >
            <Select.Option value="">All Providers</Select.Option>
            {get("providers").map((r) => (
              <Select.Option value={r.Name}>{r.Name}</Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={6}>
          <Select
            value={this.state.route}
            placeholder="Select Route..."
            showSearch
            optionFilterProp="children"
            style={{ width: "98%" }}
            onChange={(val) => this.setState({ route: val })}
          >
            <Select.Option value="">All Routes</Select.Option>
            {get("routes").map((route) => {
              return (
                <Select.Option value={route.id}>{route.Name}</Select.Option>
              );
            })}
          </Select>
        </Col>
        <Col span={6}>
          <span style={{ float: "right" }}>
            {" "}
            <Button
              type="normal"
              onClick={() => this.LoadData()}
              shape="circle"
              icon="sync"
            />{" "}
            &nbsp;
            <DatePicker
              defaultValue={moment(this.state.date, "YYYY-MM-DD")}
              format={"YYYY-MM-DD"}
              onChange={(val) => {
                this.setState({ date: val.format("YYYY-MM-DD") });
              }}
            />
          </span>
        </Col>
      </Row>
    );

    var schedules = get("schedules").filter((r) => r.Year === window.year);

    if (get("admin").role === "Transporter") {
      schedules = schedules.filter((r) => {
        var vehicle = keyValue(get("vehicles"))[r.VehicleId];
        return get("admin").user.providerid == vehicle.ProviderId;
      });
    }

    //skip future schedulles
    schedules = schedules.filter((s) => {
      let A = moment(this.state.date);
      let B = moment(s.created_at);
      return B.diff(A, "days") <= 0;
    });

    schedules = schedules.filter((s) => {
      var schedule = s;
      var vehicle = this.state.vehicles[schedule.VehicleId];
      //check vahilce
      if (this.props.provider) {
        return vehicle.ProviderId === this.props.provider.id;
      }
      return true;
    });

    schedules = schedules.filter((s) => {
      var schedule = s;
      var vehicle = this.state.vehicles[schedule.VehicleId];
      var driver = this.state.users[schedule.DriverId];
      var agent = this.state.users[schedule.AttendantId];

      //check vahilce
      if (
        vehicle.Provider != null &&
        vehicle.Provider.toString().includes(this.state.provider)
      ) {
        return true;
      }
      if (
        schedule.Name.toLowerCase().includes(
          this.state.search.toLowerCase().trim()
        )
      ) {
        return true;
      }

      if (
        vehicle.PlateNo.toLowerCase().includes(
          this.state.search.toLowerCase().trim()
        )
      ) {
        return true;
      }

      if (
        driver.name
          .toLowerCase()
          .includes(this.state.search.toLowerCase().trim())
      ) {
        return true;
      }

      if (
        agent.name
          .toLowerCase()
          .includes(this.state.search.toLowerCase().trim())
      ) {
        return true;
      }

      return false;
    });
    schedules = schedules.filter((s) =>
      s.Direction.includes(this.state.direction)
    );
    schedules = schedules.filter((s) =>
      s.RouteId.toString().includes(this.state.route)
    );

    const scheduled = (
      <Table
        dataSource={schedules}
        columns={[
          {
            title: "",
            dataIndex: "id",
            key: "id",
            render: (value, val, idx) => {
              var r = records.filter(
                (r) =>
                  r.ScheduleId === value &&
                  r.StartedAt.includes(this.state.date)
              );

              if (r.length > 0) {
                r = r[0];

                if (r.EndedAt != null) {
                  return (
                    <Avatar style={{ backgroundColor: "#00AF50" }}>
                      <Icon type="clock-circle" />
                    </Avatar>
                  );
                }
                return (
                  <Avatar style={{ backgroundColor: "#0E3541" }}>
                    <Icon spin type="clock-circle" />
                  </Avatar>
                );
              }
              return (
                <Avatar style={{ backgroundColor: "red" }}>
                  <Icon type="clock-circle" />
                </Avatar>
              );
            },
          },
          {
            title: "Trip",
            dataIndex: "ScheduleId",
            key: "ScheduleId",
            render: (val, obj) => (
              <span>
                {" "}
                {obj.id}: {obj.Direction} - {obj?.Name} (Route: {obj.Route})
              </span>
            ),
          },
          {
            title: "Vehicle",
            dataIndex: "ScheduleId",
            key: "ScheduleId",
            render: (val, obj) => (
              <span> {get("vehicles-map")[obj.VehicleId].PlateNo}</span>
            ),
          },
          {
            title: "Provider",
            dataIndex: "ScheduleId",
            key: "ScheduleId",
            render: (val, obj) => (
              <span> {get("vehicles-map")[obj.VehicleId].Provider}</span>
            ),
          },
          {
            title: "Agent",
            dataIndex: "AttendantId",
            key: "AttendantId",
            render: (val) =>
              get("users-map")[val]?.name +
              " (" +
              get("users-map")[val]?.phone +
              ")",
          },
          {
            title: "Driver",
            dataIndex: "DriverId",
            key: "DriverId",
            render: (val) =>
              get("users-map")[val]?.name +
              " (" +
              get("users-map")[val]?.phone +
              ")",
          },
          {
            title: "Sheduled Students",
            dataIndex: "id",
            key: "id",
            render: (val) =>
              get("students").filter(
                (r) => r.schedules.filter((rr) => rr.id === val).length > 0
              ).length,
          },
        ]}
      />
    );

    var notStarted = schedules.filter((s) => {
      var r = records.filter(
        (r) => r.ScheduleId === s.id && r.StartedAt.includes(this.state.date)
      );
      return r.length === 0;
    });

    const pending = (
      <Table
        dataSource={notStarted}
        columns={[
          {
            title: "",
            dataIndex: "id",
            key: "id",
            render: (value, val, idx) => (
              <Avatar
                style={{
                  backgroundColor:
                    moment().format("YYYY-MM-DD") === this.state.date
                      ? "darkorange"
                      : "red",
                }}
              >
                <Icon type="clock-circle" />
              </Avatar>
            ),
          },
          {
            title: "Trip",
            dataIndex: "ScheduleId",
            key: "ScheduleId",
            render: (val, obj) => (
              <span>
                {" "}
                {obj.id}: {obj.Direction} - {obj?.Name} (Route: {obj.Route})
              </span>
            ),
          },
          {
            title: "Vehicle",
            dataIndex: "ScheduleId",
            key: "ScheduleId",
            render: (val, obj) => (
              <span> {get("vehicles-map")[obj.VehicleId].PlateNo}</span>
            ),
          },
          {
            title: "Provider",
            dataIndex: "ScheduleId",
            key: "ScheduleId",
            render: (val, obj) => (
              <span> {get("vehicles-map")[obj.VehicleId].Provider}</span>
            ),
          },
          {
            title: "Agent",
            dataIndex: "AttendantId",
            key: "AttendantId",
            render: (val) =>
              get("users-map")[val].name +
              " (" +
              get("users-map")[val].phone +
              ")",
          },
          {
            title: "Driver",
            dataIndex: "DriverId",
            key: "DriverId",
            render: (val) =>
              get("users-map")[val].name +
              " (" +
              get("users-map")[val].phone +
              ")",
          },
          {
            title: "Sheduled Students",
            dataIndex: "id",
            key: "id",
            render: (val) =>
              get("students").filter(
                (r) => r.schedules.filter((rr) => rr.id === val).length > 0
              ).length,
          },
        ]}
      />
    );

    let active_records = records
      .filter((r) => r.EndedAt === null)
      .filter((r) => r.StartedAt.includes(this.state.date));
    let complete_records = records
      .filter((r) => r.EndedAt !== null)
      .filter((r) => r.StartedAt.includes(this.state.date));

    const table = (
      <Table
        dataSource={this.state.page === "2" ? active_records : complete_records}
        columns={[
          {
            title: "",
            dataIndex: "EndedAt",
            key: "EndedAt",
            render: (val) =>
              val == null ? (
                <Icon style={{ color: "black" }} type="loading" spin />
              ) : (
                <Icon style={{ color: "green" }} type="check-circle" />
              ),
          },
          {
            title: "Trip",
            dataIndex: "ScheduleId",
            key: "ScheduleId",
            render: (val, obj) => (
              <span>
                {" "}
                {obj.TripId}: {get("schedules-map")[val].Direction} -{" "}
                {get("schedules-map")[val].Name} (Route:{" "}
                {get("schedules-map")[val].Route})
              </span>
            ),
          },
          {
            title: "Vehicle",
            dataIndex: "ScheduleId",
            key: "ScheduleId",
            render: (val, obj) => (
              <span>
                {" "}
                {
                  get("vehicles-map")[get("schedules-map")[val].VehicleId]
                    .PlateNo
                }
              </span>
            ),
          },
          ,
          {
            title: "Provider",
            dataIndex: "ScheduleId",
            key: "ScheduleId",
            render: (val, obj) => (
              <span>
                {" "}
                {
                  get("vehicles-map")[get("schedules-map")[val].VehicleId]
                    .Provider
                }
              </span>
            ),
          },
          {
            title: "Agent",
            dataIndex: "ScheduleId",
            key: "ScheduleId",
            render: (val) =>
              get("users-map")[get("schedules-map")[val].AttendantId].name +
              " (" +
              get("users-map")[get("schedules-map")[val].AttendantId].phone +
              ")",
          },
          {
            title: "Driver",
            dataIndex: "ScheduleId",
            key: "ScheduleId",
            render: (val) =>
              get("users-map")[get("schedules-map")[val].DriverId].name +
              " (" +
              get("users-map")[get("schedules-map")[val].DriverId].phone +
              ")",
          },
          {
            title: "Started At",
            dataIndex: "StartedAt",
            key: "StartedAt",
            render: (val) =>
              moment(val, "YYYY-MM-DD hh:mm:ss").format("MMM Do h:mm:ss a"),
          },
          {
            title: "Ended At",
            dataIndex: "EndedAt",
            key: "EndedAt",
            render: (val) =>
              val === null ? (
                <Icon color="green" spin type="clock-circle" />
              ) : (
                moment(val, "YYYY-MM-DD hh:mm:ss").format("MMM Do h:mm:ss a")
              ),
          },
          {
            title: "",
            dataIndex: "TripId",
            key: "TripId",
            render: (val, obj) => (
              <span>
                <Button
                  hidden={auth.noView(auth.AccessTripInfo)}
                  onClick={() => {
                    this.setState({ showTripInfo: true, trip: obj });
                  }}
                  size="small"
                  shape="round"
                  icon="branches"
                  disabled={!obj}
                >
                  {" "}
                  Trip Info
                </Button>
              </span>
            ),
          },
        ]}
      />
    );

    message.destroy();

    return (
      <Layout.Content>
        <Card
          bordered={false}
          loading={this.state.loading}
          style={{ marginTop: -15 }}
        >
          <br />
          <Radio.Group
            value={this.state.page}
            buttonStyle="solid"
            onChange={(e) => this.setState({ page: e.target.value })}
          >
            <Radio.Button value="0">
              Scheduled Trips ({schedules.length})
            </Radio.Button>
            <Radio.Button value="1" disabled={notStarted.length === 0}>
              {moment().format("YYYY-MM-DD") === this.state.date
                ? "Pending"
                : "Not Started"}{" "}
              ({notStarted.length})
            </Radio.Button>
            <Radio.Button value="2" disabled={active_records.length === 0}>
              Active Trips ({active_records.length})
            </Radio.Button>
            <Radio.Button value="3" disabled={complete_records.length === 0}>
              Completed Trips ({complete_records.length})
            </Radio.Button>
          </Radio.Group>

          <Radio.Group
            style={{ float: "right" }}
            value={this.state.direction}
            buttonStyle="solid"
            onChange={(val) => this.setState({ direction: val.target.value })}
          >
            <Radio.Button value="">All</Radio.Button>
            <Radio.Button value="To School">To School</Radio.Button>
            <Radio.Button value="From School">From School</Radio.Button>
          </Radio.Group>

          <br />
          <br />
          {filterForm}
          <br />
          <br />

          {this.state.page === "0" && scheduled}
          {this.state.page === "1" && pending}
          {(this.state.page === "2" || this.state.page === "3") && table}
        </Card>
        {this.state.trip != null && (
          <TripInfo
            visble={this.state.showTripInfo}
            data={this.state.trip}
            onCancel={() => {
              this.setState({ showTripInfo: false });
              this.clearTripData();
              this.LoadData();
            }}
            onClose={() => {
              this.setState({ showTripInfo: false });
              this.clearTripData();
            }}
          />
        )}
      </Layout.Content>
    );
  }
}

/////////////////////////////////////////////Trip info//////////////////////////////////////
class TripInfo extends Component {
  state = {
    search: "",
    showActions: false,
    name: "TripInfo",
    students: [],
    loading: true,
    sendMessage: false,
    isDataLoaded: false,
    messages: [],
    tripData: [],
  };

  LoadData() {
    this.setState({ data: [], isDataLoaded: false });
    localStorage.removeItem("tripStudents");
    localStorage.removeItem("curTrip");

    axios
      .get("/m/trip/student?TripId=" + this.props.data.TripId)
      .then(
        function (response) {
          console.log(response.data, "New Student Trip Data Loaded");

          localStorage.setItem("tripStudents", JSON.stringify(response.data));

          this.setState({
            students: response.data,
            loading: false,
          });

          this.fetchTripData(this.props.data.TripId);
        }.bind(this)
      )
      .catch(
        function (error) {
          message.error(error["message"]);
          this.setState({ loading: false, isDataLoaded: false });
        }.bind(this)
      );
  }

  fetchTripData(tripId) {
    axios
      .get(`/geolocation/trip_id?id=${tripId}`)
      .then((response) => {
        const newCoordinates = response.data;
        console.log("Trip Data:", newCoordinates);

        // Prevent unnecessary state updates
        if (
          JSON.stringify(newCoordinates) === JSON.stringify(this.state.tripData)
        ) {
          return;
        }

        this.setState({ tripData: newCoordinates }, () => {
          console.log("Trip Data Updated:", this.state.tripData);
          console.log("Students Data:", this.state.students);

          // Check if both students and tripData are available
          const hasTripData = Array.isArray(this.state.tripData)
            ? this.state.tripData.length > 0
            : Object.keys(this.state.tripData).length > 0;

          const hasStudents = Array.isArray(this.state.students)
            ? this.state.students.length > 0
            : false;

          if (hasTripData && hasStudents) {
            console.log("Both datasets are available. Enabling button...");
            this.setState({ isDataLoaded: true }); // Enable the button
          }
        });

        // Update local storage only if new tripData are different
        let curTrip = JSON.parse(localStorage.getItem("tripData")) || {};
        curTrip.tripData = newCoordinates;
        localStorage.setItem("tripData", JSON.stringify(curTrip));
      })
      .catch((error) => {
        this.setState({ isDataLoaded: false });
        message.error("Failed to fetch tripData: " + error.message);
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data.TripId !== this.props.data.TripId) {
      console.log("Trip changed! Updating local storage...");
      this.LoadData();
    }
  }

  componentDidMount() {
    this.LoadData();
    this.fetchTripData(this.props.data.TripId);

    // Fetch coordinates every 2 seconds
    // this.interval = setInterval(() => {
    //   this.fetchTripData(this.props.data.TripId);
    // }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    var trip = this.props.data;
    const { isDataLoaded } = this.state;
    var schedule = get("schedules-map")[trip.ScheduleId];
    var route = get("routes-map")[schedule.RouteId];
    var vehicle = get("vehicles-map")[schedule.VehicleId];
    var attendant = get("users-map")[schedule.AttendantId];
    var driver = get("users-map")[schedule.DriverId];

    var active = trip.EndedAt == null;

    var students = this.state.students;

    //students=students.sort((a,b)=>(a.AlightedAt>b.AlightedAt)?1:-1);

    students = students.filter((r) =>
      r.Name.toLowerCase().includes(this.state.search.toLowerCase())
    );

    var expected = students;
    var present = students.filter((r) => r.BoardedAt !== null);
    var onboard = students.filter(
      (r) => r.BoardedAt !== null && r.AlightedAt === null
    );
    var alighted = students.filter(
      (r) => r.BoardedAt !== null && r.AlightedAt !== null
    );
    var absent = students.filter((r) => r.BoardedAt === null);

    var stations = route.stations;
    if (trip.Direction == "From School") {
      stations = stations.reverse();
    }

    //sort the trips
    var obj = trip;
    obj.Schedule = schedule;
    obj.Vehicle = vehicle;
    obj.Attendant = attendant;
    obj.Driver = driver;
    localStorage.setItem("curTrip", JSON.stringify(obj));

    return (
      <Drawer
        visible={this.props.visble}
        width={900}
        onClose={this.props.onClose}
        title={
          schedule.Name +
          " - " +
          moment(trip.StartedAt, "YYYY-MM-DD hh:mm:ss").format(
            "MMM Do h:mm:ss a"
          )
        }
      >
        <Message
          onClose={() => this.setState({ sendMessage: false })}
          visible={this.state.sendMessage}
          data={this.state.messages}
        />

        <Row style={{ marginTop: 50 }}>
          <List loading={this.state.loading} grid={{ gutter: 2, column: 3 }}>
            <List.Item>
              <List.Item.Meta
                title={"Trip Type"}
                description={schedule.Direction}
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta title={"Schedule"} description={schedule.Name} />
            </List.Item>
            <List.Item>
              <List.Item.Meta title={"Route"} description={route.Name} />
            </List.Item>
          </List>
        </Row>
        <Divider />
        <Input.Search
          placeholder="Track Student"
          onSearch={(value) => this.setState({ search: value })}
          style={{ width: 200 }}
          enterButton
          onChange={(txt) => {
            if (txt.target.value.trim().length === 0) {
              this.setState({ search: txt.target.value });
            }
          }}
        />
        <Divider />
        <br />
        <Tabs>
          <Tabs.TabPane tab="Tracker" key="0">
            <Row>
              <Col span={6} style={{ textAlign: "center" }}>
                <Progress
                  type="dashboard"
                  style={{ marginTop: 20 }}
                  percent={((present.length / expected.length) * 100).toFixed(
                    1
                  )}
                  strokeColor={{
                    "0%": "#108ee9",
                    "100%": "#87d068",
                  }}
                />
                <h6 style={{ marginTop: -40 }}>Capacity</h6>
                <br />
                <Progress
                  type="dashboard"
                  style={{ marginTop: 20 }}
                  percent={((onboard.length / present.length) * 100).toFixed(1)}
                  strokeColor={{
                    "0%": "#108ee9",
                    "100%": "#87d068",
                  }}
                />
                <h6 style={{ marginTop: -40 }}>On-Board</h6>
                <br />
                <Progress
                  type="dashboard"
                  style={{ marginTop: 20 }}
                  percent={((alighted.length / present.length) * 100).toFixed(
                    1
                  )}
                  strokeColor={{
                    "0%": "#108ee9",
                    "100%": "#87d068",
                  }}
                />
                <h6 style={{ marginTop: -40 }}>Alighted</h6>
                <br />
              </Col>

              <Col span={18}>
                <Card loading={this.state.loading} bordered={false}>
                  <Button
                    hidden={auth.noView(auth.AccessTripTracker)}
                    shape="round"
                    icon="car"
                    disabled={!isDataLoaded}
                    onClick={() => {
                      window.open(
                        `gmaps-track.html?id=${trip.TripId}`,
                        "_blank"
                      );
                    }}
                  >
                    Track Vehicle
                  </Button>
                  <br /> <br /> <br />
                  <Timeline>
                    <Timeline.Item
                      dot={
                        <Icon
                          type="clock-circle-o"
                          style={{ fontSize: "16px", color: "#00AF50" }}
                        />
                      }
                    >
                      <p>Trip Started At</p>
                      <p>
                        {moment(trip.StartedAt, "YYYY-MM-DD hh:mm:ss").format(
                          "MMM Do h:mm:ss a"
                        )}
                      </p>
                    </Timeline.Item>
                    {stations.map(
                      function (data, idx) {
                        if (trip.Direction === "To School") {
                          if (
                            students.filter(
                              (r) =>
                                r.StationId === data.id && r.BoardedAt != null
                            ).length > 0
                          ) {
                            var boardedAt = this.state.students.filter(
                              (r) =>
                                r.StationId === data.id && r.BoardedAt != null
                            )[0].BoardedAt;
                            return (
                              <Timeline.Item
                                dot={
                                  <Icon
                                    type="check-circle"
                                    style={{
                                      fontSize: "16px",
                                      color: "#00AF50",
                                    }}
                                  />
                                }
                              >
                                <p>{data.Name}</p>
                                <p>
                                  {moment(
                                    boardedAt,
                                    "YYYY-MM-DD hh:mm:ss"
                                  ).format("h:mm:ss a")}
                                </p>
                                <Popover
                                  placement="right"
                                  title={"Students Boarded"}
                                  content={this.state.students
                                    .filter(
                                      (r) =>
                                        r.StationId === data.id &&
                                        r.BoardedAt != null
                                    )
                                    .map((r) => (
                                      <span>
                                        <Tag color={"#00AF50"}>{r.Name}</Tag>
                                        <br />
                                        <br />
                                      </span>
                                    ))}
                                >
                                  <p
                                    style={{
                                      color: "green",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {
                                      this.state.students.filter(
                                        (r) =>
                                          r.StationId === data.id &&
                                          r.BoardedAt != null
                                      ).length
                                    }{" "}
                                    Boarded
                                  </p>
                                </Popover>
                              </Timeline.Item>
                            );
                          }
                        } else {
                          if (
                            students.filter(
                              (r) =>
                                r.StationId === data.id && r.AlightedAt != null
                            ).length > 0
                          ) {
                            var AlightedAt = this.state.students.filter(
                              (r) =>
                                r.StationId === data.id && r.AlightedAt != null
                            )[0].AlightedAt;
                            return (
                              <Timeline.Item
                                dot={
                                  <Icon
                                    type="check-circle"
                                    style={{
                                      fontSize: "16px",
                                      color: "#00AF50",
                                    }}
                                  />
                                }
                              >
                                <p>{data.Name}</p>
                                <p>
                                  {moment(
                                    AlightedAt,
                                    "YYYY-MM-DD hh:mm:ss"
                                  ).format("h:mm:ss a")}
                                </p>
                                <Popover
                                  placement="right"
                                  title={"Students Alighted"}
                                  content={this.state.students
                                    .filter(
                                      (r) =>
                                        r.StationId === data.id &&
                                        r.AlightedAt != null
                                    )
                                    .map((r) => (
                                      <span>
                                        <Tag color={"#00AF50"}>{r.Name}</Tag>
                                        <br />
                                        <br />
                                      </span>
                                    ))}
                                >
                                  <p
                                    style={{
                                      color: "green",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {
                                      this.state.students.filter(
                                        (r) =>
                                          r.StationId === data.id &&
                                          r.AlightedAt != null
                                      ).length
                                    }{" "}
                                    Alighted
                                  </p>
                                </Popover>
                              </Timeline.Item>
                            );
                          }
                        }
                      }.bind(this)
                    )}
                    {/* {stations.map(function(data, idx) {
                
               
                    
                  //check if ended trip
                  if(trip.EndedAt!==null) {
                    return ( 
                      <Timeline.Item
                       dot={<Icon type="exclamation-circle" style={{ fontSize: '16px',color:"orange" }} />} 
                       key={idx}>{data.Name}</Timeline.Item>
                      )
                  } else {
                    return ( 
                      <Timeline.Item
                       dot={<Icon type="sync" spin style={{ fontSize: '16px',color:"darkorange" }} />} 
                       key={idx}>{data.Name}</Timeline.Item>
                      )
                  }
                  
                        
                }.bind(this))} */}

                    <Timeline.Item
                      dot={
                        active ? (
                          <Icon
                            type="sync"
                            spin
                            style={{ fontSize: "16px", color: "#00AF50" }}
                          />
                        ) : (
                          <Icon
                            type="home"
                            style={{ fontSize: "16px", color: "#00AF50" }}
                          />
                        )
                      }
                      color="#00AF50"
                    >
                      {active ? (
                        <p>In Progress...</p>
                      ) : (
                        <span>
                          {" "}
                          <p>
                            {trip.Direction === "To School"
                              ? "Arrived at School"
                              : "Trip Ended"}
                          </p>
                          <p>
                            {moment(trip.EndedAt, "YYYY-MM-DD hh:mm:ss").format(
                              "MMM Do h:mm:ss a"
                            )}
                          </p>
                        </span>
                      )}
                    </Timeline.Item>
                  </Timeline>
                </Card>
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab={"Expected (" + expected.length + ")"} key="1">
            <Card
              bordered={false}
              title={"Expected Students  (" + expected.length + ")"}
              extra={
                <Button
                  hidden={get("admin").role !== "Administrator"}
                  onClick={() => {
                    var messages = [];
                    expected.forEach((student) => {
                      get("students-map")[student.id].guardians.forEach(
                        (guardian) => {
                          var obj = JSON.parse(JSON.stringify(student));
                          obj["GuardianName"] = guardian.name;
                          obj["Phone"] = guardian.phone;
                          obj["Email"] = guardian.phone;
                          obj["RouteName"] =
                            get("routes-map")[student.RouteId].Name;
                          obj["StationName"] =
                            get("stations-map")[student.StationId].Name;
                          obj["HimHer"] =
                            guardian.Gender == "Male" ? "him" : "her";
                          obj["HisHer"] =
                            guardian.Gender == "Male" ? "his" : "hers";
                          obj["HisHers"] =
                            guardian.Gender == "Male" ? "his" : "hers";
                          obj["SonDaughter"] =
                            guardian.Gender == "Male" ? "son" : "daughter";
                          messages.push(obj);
                        }
                      );
                    });
                    this.setState({ messages: messages, sendMessage: true });
                  }}
                  shape="round"
                >
                  Contact Guardians
                </Button>
              }
            >
              <List
                loading={this.state.loading}
                grid={{ gutter: 2, column: 2 }}
              >
                {expected.map(function (data, idx) {
                  return (
                    <List.Item>
                      <List.Item.Meta
                        avatar={
                          <Avatar style={{ backgroundColor: "#00AF50" }}>
                            {idx + 1}
                          </Avatar>
                        }
                        title={data.Name}
                        description={
                          "  Class " + data.Class + " " + data.Stream
                        }
                      />
                    </List.Item>
                  );
                })}
              </List>
            </Card>
          </Tabs.TabPane>
          <Tabs.TabPane tab={"Checked-In (" + present.length + ")"} key="2">
            <Card
              bordered={false}
              title={"Checked-In Students  (" + present.length + ")"}
              extra={
                <Button
                  onClick={() => {
                    var messages = [];

                    present.forEach((student) => {
                      get("students-map")[student.id].guardians.forEach(
                        (guardian) => {
                          var obj = student;
                          obj["GuardianName"] = guardian.name;
                          obj["Phone"] = guardian.phone;
                          obj["Email"] = guardian.phone;
                          obj["RouteName"] =
                            get("routes-map")[student.RouteId].Name;
                          obj["StationName"] =
                            get("stations-map")[student.StationId].Name;
                          obj["HimHer"] =
                            guardian.Gender == "Male" ? "him" : "her";
                          obj["HisHer"] =
                            guardian.Gender == "Male" ? "his" : "hers";
                          obj["HisHers"] =
                            guardian.Gender == "Male" ? "his" : "hers";
                          obj["SonDaughter"] =
                            guardian.Gender == "Male" ? "son" : "daughter";
                          messages.push(obj);
                        }
                      );
                    });

                    this.setState({ messages: messages, sendMessage: true });
                  }}
                  shape="round"
                >
                  Contact Guardians
                </Button>
              }
            >
              <List
                loading={this.state.loading}
                grid={{ gutter: 2, column: 2 }}
              >
                {present.map(function (data, idx) {
                  return (
                    <List.Item>
                      <List.Item.Meta
                        avatar={
                          <Avatar style={{ backgroundColor: "#00AF50" }}>
                            {idx + 1}
                          </Avatar>
                        }
                        title={data.Name}
                        description={moment(
                          data.BoardedAt,
                          "YYYY-MM-DD hh:mm:ss"
                        ).format("h:mm:ss a")}
                      />
                    </List.Item>
                  );
                })}
              </List>
            </Card>
          </Tabs.TabPane>
          <Tabs.TabPane tab={"On-Board (" + onboard.length + ")"} key="3">
            {onboard.length > 0 && (
              <Card
                title={"On-Board Students (" + onboard.length + ")"}
                bordered={false}
                extra={
                  <Button
                    onClick={() => {
                      var messages = [];

                      onboard.forEach((student) => {
                        get("students-map")[student.id].guardians.forEach(
                          (guardian) => {
                            var obj = student;
                            obj["GuardianName"] = guardian.name;
                            obj["Phone"] = guardian.phone;
                            obj["Email"] = guardian.phone;
                            obj["RouteName"] =
                              get("routes-map")[student.RouteId].Name;
                            obj["StationName"] =
                              get("stations-map")[student.StationId].Name;
                            obj["HimHer"] =
                              guardian.Gender == "Male" ? "him" : "her";
                            obj["HisHer"] =
                              guardian.Gender == "Male" ? "his" : "her";
                            obj["HisHers"] =
                              guardian.Gender == "Male" ? "his" : "hers";
                            obj["SonDaughter"] =
                              guardian.Gender == "Male" ? "son" : "daughter";
                            messages.push(obj);
                          }
                        );
                      });

                      this.setState({ messages: messages, sendMessage: true });
                    }}
                    shape="round"
                  >
                    Contact Guardians
                  </Button>
                }
              >
                <List
                  loading={this.state.loading}
                  grid={{ gutter: 2, column: 2 }}
                >
                  {onboard.map(function (data, idx) {
                    return (
                      <List.Item>
                        <List.Item.Meta
                          avatar={
                            <Avatar style={{ backgroundColor: "darkorange" }}>
                              {idx + 1}
                            </Avatar>
                          }
                          title={data.Name}
                          description={
                            "  Class " + data.Class + " " + data.Stream
                          }
                        />
                      </List.Item>
                    );
                  })}
                </List>
              </Card>
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab={"Alighted (" + alighted.length + ")"} key="4">
            {alighted.length > 0 && (
              <Card
                title={"Alighted Students (" + alighted.length + ")"}
                bordered={false}
                extra={
                  <Button
                    onClick={() => {
                      var messages = [];

                      alighted.forEach((student) => {
                        get("students-map")[student.id].guardians.forEach(
                          (guardian) => {
                            var obj = student;
                            obj["GuardianName"] = guardian.name;
                            obj["Phone"] = guardian.phone;
                            obj["Email"] = guardian.phone;
                            obj["RouteName"] =
                              get("routes-map")[student.RouteId].Name;
                            obj["StationName"] =
                              get("stations-map")[student.StationId].Name;
                            obj["HimHer"] =
                              guardian.Gender == "Male" ? "him" : "her";
                            obj["HisHer"] =
                              guardian.Gender == "Male" ? "his" : "her";
                            obj["HisHers"] =
                              guardian.Gender == "Male" ? "his" : "hers";
                            obj["SonDaughter"] =
                              guardian.Gender == "Male" ? "son" : "daughter";
                            messages.push(obj);
                          }
                        );
                      });

                      this.setState({ messages: messages, sendMessage: true });
                    }}
                    shape="round"
                  >
                    Contact Guardians
                  </Button>
                }
              >
                <List
                  loading={this.state.loading}
                  grid={{ gutter: 2, column: 2 }}
                >
                  {alighted.map(function (data, idx) {
                    return (
                      <List.Item>
                        <List.Item.Meta
                          avatar={
                            <Avatar style={{ backgroundColor: "green" }}>
                              {idx + 1}
                            </Avatar>
                          }
                          title={data.Name}
                          description={
                            moment(
                              data.AlightedAt,
                              "YYYY-MM-DD hh:mm:ss"
                            ).format("h:mm:ss a") +
                            " (" +
                            moment
                              .duration(
                                moment(
                                  data.AlightedAt,
                                  "YYYY-MM-DD hh:mm:ss"
                                ).diff(
                                  moment(data.BoardedAt, "YYYY-MM-DD hh:mm:ss")
                                )
                              )
                              .asMinutes()
                              .toFixed() +
                            " Mins)"
                          }
                        />
                      </List.Item>
                    );
                  })}
                </List>
              </Card>
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab={"Pending/Absent (" + absent.length + ")"} key="5">
            {absent.length > 0 && (
              <Card
                title={"Pending/Absent Students (" + absent.length + ")"}
                bordered={false}
                extra={
                  <Button
                    onClick={() => {
                      var messages = [];

                      absent.forEach((student) => {
                        get("students-map")[student.id].guardians.forEach(
                          (guardian) => {
                            var obj = student;
                            obj["GuardianName"] = guardian.name;
                            obj["Phone"] = guardian.phone;
                            obj["Email"] = guardian.phone;
                            obj["RouteName"] =
                              get("routes-map")[student.RouteId].Name;
                            obj["StationName"] =
                              get("stations-map")[student.StationId].Name;
                            obj["HimHer"] =
                              guardian.Gender == "Male" ? "him" : "her";
                            obj["HisHer"] =
                              guardian.Gender == "Male" ? "his" : "her";
                            obj["HisHers"] =
                              guardian.Gender == "Male" ? "his" : "hers";
                            obj["SonDaughter"] =
                              guardian.Gender == "Male" ? "son" : "daughter";
                            messages.push(obj);
                          }
                        );
                      });

                      this.setState({ messages: messages, sendMessage: true });
                    }}
                    shape="round"
                  >
                    Contact Guardians
                  </Button>
                }
              >
                <List
                  loading={this.state.loading}
                  grid={{ gutter: 2, column: 2 }}
                >
                  {absent.map(function (data, idx) {
                    return (
                      <List.Item>
                        <List.Item.Meta
                          avatar={
                            <Avatar style={{ backgroundColor: "gray" }}>
                              {idx + 1}
                            </Avatar>
                          }
                          title={data.Name}
                          description={
                            "  Class " + data.Class + " " + data.Stream
                          }
                        />
                      </List.Item>
                    );
                  })}
                </List>
              </Card>
            )}
          </Tabs.TabPane>
        </Tabs>
        <Divider />

        <Divider>Operators</Divider>
        <Row>
          <List loading={this.state.loading} grid={{ gutter: 2, column: 2 }}>
            <List.Item>
              <List.Item.Meta
                title={"Driver"}
                description={driver.name + " (" + driver.phone + ")"}
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                title={"Bus Agent"}
                description={attendant.name + " (" + attendant.phone + ")"}
              />
            </List.Item>
          </List>
        </Row>
        <Row>
          <List loading={this.state.loading} grid={{ gutter: 2, column: 2 }}>
            <List.Item>
              <List.Item.Meta title={"Vehicle"} description={vehicle.Model} />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                title={"Plate Number"}
                description={vehicle.PlateNo}
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta title={"Type"} description={vehicle.Type} />
            </List.Item>
          </List>
        </Row>
        <Divider />
        <Row hidden={get("admin").role !== "Administrator"}>
          <a
            style={{ color: "#00AF50" }}
            href="javascript:"
            onClick={() =>
              this.setState({ showActions: !this.state.showActions })
            }
          >
            {" "}
            {this.state.showActions
              ? "Hide Advanced Admin Actions"
              : "Show Advanced Admin Actions"}{" "}
          </a>
          <br />
          <br />
          <span hidden={!this.state.showActions}>
            <Popconfirm
              placement="top"
              title="End/Complete Trip?"
              onConfirm={() => {
                message.loading("Ending trip...", 60);
                Axios.patch("/m/trips", { TripId: trip.TripId }).then((r) => {
                  message.destroy();
                  message.success("Trip Ended");
                  this.props.onCancel();
                });
              }}
            >
              <Button hidden={trip.EndedAt !== null} shape="round">
                Force-End Trip
              </Button>
            </Popconfirm>
            &nbsp; &nbsp;
            <Popconfirm
              placement="top"
              title="Cancel Trip?"
              onConfirm={() => {
                message.loading("Cancelling trip...", 60);
                Axios.delete("/m/trips/" + trip.TripId).then((r) => {
                  message.destroy();
                  message.success("Trip cancelled");
                  this.props.onCancel();
                });
              }}
            >
              <Button shape="round">Cancel Trip</Button>
            </Popconfirm>
          </span>
        </Row>
      </Drawer>
    );
  }
}

export default Trips;
