import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  Table,
  Tag,
  Card,
  Row,
  Col,
  Radio,
  Input,
  message,
  DatePicker,
} from "antd";
import axios from "axios";
import moment from "moment";
import {
  SwapOutlined,
  ArrowDownOutlined,
  ArrowUpOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
} from "@ant-design/icons";

const WidgetCard = ({ title, value, icon, color }) => (
  <Card
    style={{
      borderRadius: 12,
      boxShadow: "0 4px 12px rgba(0,0,0,0.05)",
    }}
    bodyStyle={{ padding: 24 }}
  >
    <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
      <div
        style={{
          width: 54,
          height: 54,
          borderRadius: 16,
          background: color,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#fff",
          fontSize: 28,
        }}
      >
        {icon}
      </div>
      <div>
        <div style={{ fontSize: 13, opacity: 0.65 }}>{title}</div>
        <div style={{ fontSize: 14, fontWeight: 600 }}>{value}</div>
      </div>
    </div>
  </Card>
);

const Attendance = () => {
  const [rawData, setRawData] = useState([]);
  const [availabilityData, setAvailabilityData] = useState([]);
  const [presentData, setPresentData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [direction, setDirection] = useState("");
  const [availability, setAvailability] = useState("");
  const [searchText, setSearchText] = useState("");
  const [date, setDate] = useState(moment());

  const [counts, setCounts] = useState({
    checkIns: 0,
    checkOuts: 0,
    present: 0,
    outNoIn: 0,
    allDay: 0,
    morning: 0,
    evening: 0,
    totalActivity: 0,
  });

  const prevDateRef = useRef();

  const isSameDate = (d1, d2) =>
    d1 && d2 && d1.format("YYYY-MM-DD") === d2.format("YYYY-MM-DD");

  useEffect(() => {
    fetchAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isSameDate(prevDateRef.current, date)) {
      fetchAllData();
      prevDateRef.current = date;
    }
  }, [date]);

  const fetchAllData = async () => {
    try {
      setLoading(true);
      const dateStr = date.format("YYYY-MM-DD");

      const [
        activityRes,
        checkInRes,
        checkOutRes,
        presentRes,
        outNoInRes,
        availabilityRes,
      ] = await Promise.all([
        axios.get("/attendance/daily/activity", { params: { Date: dateStr } }),
        axios.get("/attendance/daily/checkin", { params: { Date: dateStr } }),
        axios.get("/attendance/daily/checkout", { params: { Date: dateStr } }),
        axios.get("/attendance/daily/present", { params: { Date: dateStr } }),
        axios.get("/attendance/daily/out_no_in", { params: { Date: dateStr } }),
        axios.get("/daily/student/do-not-pick", { params: { date: dateStr } }),
      ]);

      const activityData = activityRes.data.data || [];
      const availData = availabilityRes.data.data || [];
      const presentDataFromApi = presentRes.data.data || [];

      setRawData(activityData);
      setAvailabilityData(availData);
      setPresentData(presentDataFromApi);

      const allDayCount = availData.filter(
        (item) => item.inavailability === "All_day"
      ).length;
      const morningCount = availData.filter(
        (item) =>
          item.inavailability === "All_day" || item.inavailability === "Morning"
      ).length;
      const eveningCount = availData.filter(
        (item) =>
          item.inavailability === "All_day" || item.inavailability === "Evening"
      ).length;

      setCounts({
        checkIns: checkInRes.data.total_students || 0,
        checkOuts: checkOutRes.data.total_students || 0,
        present: presentRes.data.total_students || 0,
        outNoIn: outNoInRes.data.total_students || 0,
        allDay: allDayCount,
        morning: morningCount,
        evening: eveningCount,
        totalActivity: activityData.length,
      });
    } catch (error) {
      message.error("Failed to load data");
    } finally {
      setLoading(false);
    }
  };

  const filteredData = useMemo(() => {
    if (direction === "AVAILABILITY") {
      let data = [...availabilityData];

      if (availability === "Morning") {
        data = data.filter(
          (item) =>
            item.inavailability === "All_day" ||
            item.inavailability === "Morning"
        );
      } else if (availability === "Evening") {
        data = data.filter(
          (item) =>
            item.inavailability === "All_day" ||
            item.inavailability === "Evening"
        );
      } else if (availability === "All_day") {
        data = data.filter((item) => item.inavailability === "All_day");
      }

      return data;
    }

    if (direction === "PRESENT") {
      return [...presentData];
    }

    let data = [...rawData];

    if (direction === "IN") {
      data = data.filter((item) => item.Direction === "IN");
    } else if (direction === "OUT") {
      data = data.filter((item) => item.Direction === "OUT");
    } else if (direction === "OUT_NO_IN") {
      const checkedIn = new Set();
      rawData.forEach((item) => {
        if (item.Direction === "IN") checkedIn.add(item.Adm);
      });
      data = rawData.filter(
        (item) => item.Direction === "OUT" && !checkedIn.has(item.Adm)
      );
    }

    if (searchText) {
      const searchLower = searchText.toLowerCase();
      data = data.filter((item) => {
        const name = item.Name || item.student_name || "";
        const adm = item.Adm || item.adm || "";
        return (
          name.toLowerCase().includes(searchLower) ||
          adm.toLowerCase().includes(searchLower)
        );
      });
    }

    return data;
  }, [
    rawData,
    presentData,
    availabilityData,
    direction,
    availability,
    searchText,
  ]);

  const displayedCounts = useMemo(() => {
    if (direction === "AVAILABILITY") {
      return {
        allActivity: counts.totalActivity,
        checkIns: counts.checkIns,
        checkOuts: counts.checkOuts,
        present: counts.present,
        outNoIn: counts.outNoIn,
        allDay:
          availability === "All_day" ? filteredData.length : counts.allDay,
        morning:
          availability === "Morning" ? filteredData.length : counts.morning,
        evening:
          availability === "Evening" ? filteredData.length : counts.evening,
      };
    }

    return {
      allActivity:
        direction === "" ? filteredData.length : counts.totalActivity,
      checkIns: counts.checkIns,
      checkOuts: counts.checkOuts,
      present: counts.present,
      outNoIn: counts.outNoIn,
      allDay: counts.allDay,
      morning: counts.morning,
      evening: counts.evening,
    };
  }, [counts, direction, availability, filteredData.length]);

  const showCard = (target) => direction === "" || direction === target;

  const columns = [
    {
      title: "#",
      render: (text, record, index) => (
        <Tag color={record.Direction === "IN" ? "purple" : "blue"}>
          <strong>
            {index + 1} {record.Direction || ""}
          </strong>
        </Tag>
      ),
    },
    {
      title: "Time",
      dataIndex: "created_at",
      key: "created_at",
      render: (val) =>
        val ? (
          <span>
            <b>{moment(val).format("LTS")}</b>
            <br />
            <small>{moment(val).fromNow()}</small>
          </span>
        ) : (
          "-"
        ),
    },
    {
      title: "Adm",
      dataIndex: "Adm",
      key: "Adm",
      render: (val, record) => val || record.adm,
    },
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
      render: (val, record) => (
        <span style={{ color: "green" }}>
          {val || record.student_name || "-"}
        </span>
      ),
    },
    {
      title: "Class",
      dataIndex: "Class",
      key: "Class",
      render: (val, record) => val || record.class,
    },
    {
      title: "Stream",
      dataIndex: "Stream",
      key: "Stream",
      render: (val, record) => <Tag>{val || record.stream}</Tag>,
    },
    {
      title: "Mode",
      key: "Mode",
      render: (val, obj) => (
        <span style={{ color: "#654EF0" }}>
          {obj.CheckinMode && <b>{obj.CheckinMode}</b>} ·{" "}
          {obj.VehicleName || ""}
        </span>
      ),
    },
  ];

  return (
    <div style={{ padding: 24 }}>
      <Row gutter={[16, 16]} style={{ marginBottom: 24 }}>
        {direction === "" && (
          <Col span={6}>
            <WidgetCard
              title="All Activity"
              value={displayedCounts.allActivity}
              icon={<SwapOutlined />}
              color="#0383BF"
            />
          </Col>
        )}
        {showCard("IN") && (
          <Col span={6}>
            <WidgetCard
              title="Check-Ins"
              value={displayedCounts.checkIns}
              icon={<ArrowDownOutlined />}
              color="#7762FA"
            />
          </Col>
        )}
        {showCard("OUT") && (
          <Col span={6}>
            <WidgetCard
              title="Check-Outs"
              value={displayedCounts.checkOuts}
              icon={<ArrowUpOutlined />}
              color="#3AA4F4"
            />
          </Col>
        )}
        {showCard("PRESENT") && (
          <Col span={6}>
            <WidgetCard
              title="Present Students"
              value={displayedCounts.present}
              icon={<UserAddOutlined />}
              color="#01C68A"
            />
          </Col>
        )}
        {showCard("OUT_NO_IN") && (
          <Col span={6}>
            <WidgetCard
              title="OUT but No IN"
              value={displayedCounts.outNoIn}
              icon={<UserDeleteOutlined />}
              color="#F03B52"
            />
          </Col>
        )}
        {(direction === "" || direction === "AVAILABILITY") && (
          <Col span={6}>
            <WidgetCard
              title="Availability"
              value={
                <div style={{ display: "flex", flexWrap: "wrap", gap: 8 }}>
                  <span
                    style={{
                      background: "#e0f2fe",
                      color: "#0284c7",
                      padding: "2px 10px",
                      borderRadius: "999px",
                      fontSize: 12,
                      fontWeight: 600,
                    }}
                  >
                    {displayedCounts.allDay} All Day
                  </span>
                  <span
                    style={{
                      background: "#fef3c7",
                      color: "#b45309",
                      padding: "2px 10px",
                      borderRadius: "999px",
                      fontSize: 12,
                      fontWeight: 600,
                    }}
                  >
                    {displayedCounts.morning} Morning
                  </span>
                  <span
                    style={{
                      background: "#ede9fe",
                      color: "#7c3aed",
                      padding: "2px 10px",
                      borderRadius: "999px",
                      fontSize: 12,
                      fontWeight: 600,
                    }}
                  >
                    {displayedCounts.evening} Evening
                  </span>
                </div>
              }
              icon={<UserDeleteOutlined />}
              color="#9A3AF9"
            />
          </Col>
        )}
      </Row>

      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col span={8}>
          <Radio.Group
            value={direction}
            buttonStyle="solid"
            onChange={(e) => {
              setDirection(e.target.value);
              setAvailability("");
            }}
          >
            <Radio.Button value="">All Activity</Radio.Button>
            <Radio.Button value="IN">IN</Radio.Button>
            <Radio.Button value="OUT">OUT</Radio.Button>
            <Radio.Button value="PRESENT">PRESENT</Radio.Button>
            <Radio.Button value="OUT_NO_IN">OUT NO IN</Radio.Button>
            <Radio.Button value="AVAILABILITY">Availability</Radio.Button>
          </Radio.Group>
        </Col>

        <Col span={8}>
          <DatePicker
            value={date}
            onChange={(val) => setDate(val)}
            format="YYYY-MM-DD"
            style={{ width: "100%" }}
          />
        </Col>

        <Col span={8}>
          <Input.Search
            placeholder="Search Student"
            allowClear
            enterButton
            onSearch={(val) => setSearchText(val)}
            style={{ marginBottom: 16 }}
          />
        </Col>
      </Row>

      {direction === "AVAILABILITY" && (
        <Row style={{ marginBottom: 16 }}>
          <Radio.Group
            value={availability}
            onChange={(e) => setAvailability(e.target.value)}
            buttonStyle="solid"
          >
            <Radio.Button value="">All</Radio.Button>
            <Radio.Button value="All_day">All Day</Radio.Button>
            <Radio.Button value="Morning">Morning</Radio.Button>
            <Radio.Button value="Evening">Evening</Radio.Button>
          </Radio.Group>
        </Row>
      )}

      <Table
        loading={loading}
        columns={columns}
        dataSource={filteredData}
        rowKey={(record) => record.student_id || record.id || record.adm}
        pagination={{ pageSize: 25 }}
        bordered
      />
    </div>
  );
};

export default Attendance;
