import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
  Button,
  Alert,
  Empty,
  Spin,
  Col,
  Row,
  Table,
  message,
  Select,
} from "antd";
import {
  exportToExcel,
  exportToCSV,
  exportToPDF,
} from "../../utils/generateReports";
import {
  FileExcelOutlined,
  FileTextOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import { debounce } from "lodash";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { fetchReportFilters } from "../../utils/apiUtils";

import "antd/dist/antd.css";

const { Option } = Select;

const VehicleReport = () => {
  const location = useLocation();
  const hash = location.hash;
  const hashParams = new URLSearchParams(hash.split("?")[1]);
  const [busy, setBusy] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [text, setText] = useState("Filter Report");
  const [error, setError] = useState("");
  const [token, setToken] = useState("");
  const [baseUrl, setBaseUrl] = useState("");
  const [reportAliasId, setReportAliasId] = useState("");
  const [providerId, setProviderId] = useState("");
  const [filters, setFilters] = useState(null); 
  const [vehicleId, setVehicleId] = useState("");
  const [routeId, setRouteId] = useState("");
  const [stationId, setStationId] = useState("");
  const [uniqueReportId, setUniqueReportId] = useState("");

  const [studentType, setStudentType] = useState("");
  const [studentClass, setStudentClass] = useState("");
  const [studentId, setStudentId] = useState("");
  const [results, setResults] = useState([]);
  const [vehicle, setVehicle] = useState("");
  const [provider, setProvider] = useState("");

  // Original and Filtered Options
  const [originalProviders, setOriginalProviders] = useState([]);
  const [filteredProviders, setFilteredProviders] = useState([]);
  const [originalVehicles, setOriginalVehicles] = useState([]);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [originalRoutes, setOriginalRoutes] = useState([]);
  const [filteredRoutes, setFilteredRoutes] = useState([]);
  const [stationRoutes, setStationRoutes] = useState([]);
  const [station, setStation] = useState("");
  const [route, setRoute] = useState("");
  const [originalStations, setOriginalStations] = useState([]);
  const [filteredStations, setFilteredStations] = useState([]);
  const [originalStudentTypes, setOriginalStudentTypes] = useState([]);
  const [filteredStudentTypes, setFilteredStudentTypes] = useState([]);
  const [originalStudentClasses, setOriginalStudentClasses] = useState([]);
  const [filteredStudentClasses, setFilteredStudentClasses] = useState([]);
  const [originalStudents, setOriginalStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [vehicleProviders, setVehicleProviders] = useState([]);

  // Extract metadata from URL
  const reportName = hashParams.get("reportName");
  const startDate = hashParams.get("startDate");
  const endDate = hashParams.get("endDate");
  const noTranches = hashParams.get("noTranches");
  const minTrips = hashParams.get("minTrips");
  const percentage = hashParams.get("percentage");

  // Pagination States
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const [lastPage, setLastPage] = useState(1);

  useEffect(() => {
    let uniqueReportId = null;

    if (window.location.hash.includes("?")) {
      const queryParams = new URLSearchParams(
        window.location.hash.split("?")[1]
      );
      uniqueReportId = queryParams.get("uniqueReportId");
    }
    if (uniqueReportId) {
      setUniqueReportId(uniqueReportId);
    } else {
      console.warn("⚠️ No Unique Report ID found in URL.");
    }

    try {
    } catch (error) {}

    console.log("Extracted Unique Report ID:", uniqueReportId);
    const account = JSON.parse(localStorage.getItem("account"));
    const BaseUrl = localStorage.getItem("api");

    if (account?.access_token) {
      setToken(account.access_token);
    } else {
      message.error("Missing authentication token. Please log in again.");
    }

    if (BaseUrl) {
      setBaseUrl(BaseUrl);
    } else {
      message.error("Missing base URL. Please configure your settings.");
    }
  }, []);

  useEffect(() => {
    const loadFilters = async () => {
      if (uniqueReportId && baseUrl && token) {
        try {
          const filters = await fetchReportFilters(
            baseUrl,
            token,
            uniqueReportId
          );
          if (filters) {
            setFilters(filters);
            setVehicleProviders(filters.vehicle_providers || []);
            setStationRoutes(filters.station_routes || []);

            // Set the filter options based on the fetched data
            setOriginalRoutes(filters.routes || []);
            setFilteredRoutes(filters.routes || []);
            setOriginalStations(filters.stations || []);
            setFilteredStations(filters.stations || []);
            setOriginalStudentTypes(filters.types || []);
            setFilteredStudentTypes(filters.types || []);
            setOriginalStudentClasses(filters.student_classes || []);
            setFilteredStudentClasses(filters.student_classes || []);
            setOriginalStudents(filters.students || []);
            setFilteredStudents(filters.students || []);
            setOriginalVehicles(filters.vehicles || []);
            setFilteredVehicles(filters.vehicles || []);
            setOriginalProviders(filters.providers || []);
            setFilteredProviders(filters.providers || []);
          }
        } catch (error) {
          console.error("Error fetching filters:", error);
          message.error("Failed to fetch filters.");
        }
      }
    };

    loadFilters();
  }, [uniqueReportId, baseUrl, token]);

  const handleProviderChange = (selectedProviderId) => {
    setProvider(selectedProviderId);
    setProviderId(selectedProviderId);

    if (selectedProviderId) {
      const vehiclesForProvider = vehicleProviders
        .filter((vp) => vp.provider_id === selectedProviderId)
        .map((vp) =>
          originalVehicles.find((v) => v.vehicle_id === vp.vehicle_id)
        )
        .filter((v) => v);

      setFilteredVehicles(vehiclesForProvider);
    } else {
      setFilteredVehicles(originalVehicles);
    }
    setVehicle("");
    setVehicleId("");
  };

  const handleRouteChange = (selectedRouteId) => {
    setRoute(selectedRouteId); 
    setRouteId(selectedRouteId); 

    if (selectedRouteId) {
      const stationsForRoute = stationRoutes
        .filter((sr) => sr.route_id === selectedRouteId)
        .map((sr) =>
          originalStations.find((s) => s.station_id === sr.station_id)
        )
        .filter((s) => s);

      setFilteredStations(stationsForRoute);
    } else {
      setFilteredStations(originalStations);
    }
    setStation("");
    setStationId(""); 
  };

  const handleFilterSearch = (value, filterType) => {
    const filterMap = {
      route: {
        data: originalRoutes,
        filterFn: (item) =>
          item.route_name.toLowerCase().includes(value.toLowerCase()),
        setter: setFilteredRoutes,
      },
      station: {
        data: originalStations,
        filterFn: (item) =>
          item.station_name.toLowerCase().includes(value.toLowerCase()),
        setter: setFilteredStations,
      },
      studentType: {
        data: originalStudentTypes,
        filterFn: (item) => item.toLowerCase().includes(value.toLowerCase()),
        setter: setFilteredStudentTypes,
      },
      studentClass: {
        data: originalStudentClasses,
        filterFn: (item) => item.toLowerCase().includes(value.toLowerCase()),
        setter: setFilteredStudentClasses,
      },
      student: {
        data: originalStudents,
        filterFn: (item) =>
          item.name.toLowerCase().includes(value.toLowerCase()),
        setter: setFilteredStudents,
      },
      vehicle: {
        data: originalVehicles,
        filterFn: (item) =>
          item.vehicle.toLowerCase().includes(value.toLowerCase()),
        setter: setFilteredVehicles,
      },
      provider: {
        data: originalProviders,
        filterFn: (item) =>
          item.provider.toLowerCase().includes(value.toLowerCase()),
        setter: setFilteredProviders,
      },
    };

    const { data, filterFn, setter } = filterMap[filterType] || {};
    if (!data) return;

    if (!value) {
      setter(data);
    } else {
      const filtered = data.filter(filterFn);
      setter(filtered);
    }
  };

  // Function to Fetch Report Filters
  useEffect(() => {
    const loadFilters = async () => {
      if (uniqueReportId && baseUrl && token) {
        const filters = await fetchReportFilters(
          baseUrl,
          token,
          uniqueReportId
        );
        if (filters) {
          setFilters(filters);
        }
      }
    };

    loadFilters();
  }, [uniqueReportId, baseUrl, token]);

  // Display reports on first render
  useEffect(() => {
    if (token && baseUrl) {
      generateReport();
    }
  }, [token, baseUrl]);

  const debouncedSearch = debounce(handleFilterSearch, 300);

  const generateReport = async (page = currentPage, limit = pageSize) => {
    if (!token) {
      setError("Missing authentication token. Please log in again.");
      message.error("Missing authentication token. Please log in again.");
      return;
    }

    setBusy(true);
    setText("Filtering Report...");
    setError("");

    try {
      const url = `${baseUrl}/fastapi/vehicle/report/`;
      const params = {
        unique_report_id: uniqueReportId,
        provider_id: providerId,
        vehicle_id: vehicleId,
        route_id: routeId,
        station_id: stationId,
        student_type: studentType,
        student_class: studentClass,
        student_id: studentId,
        page,
        per_page: limit,
      };

      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const { data } = await axios.get(url, { params, headers });

      if (!data || !data.data || data.data.length === 0) {
        setError("No record matching the filter");
        message.info("No record matching the filter");
        setResults([]);
        return;
      }

      let updatedResults = [...data.data];

      if (page === data.pagination.last_page) {
        const totalRow = calculateTotals(data.aggregates);
        updatedResults.push(totalRow);
      }

      setResults(updatedResults);
      setCurrentPage(data.pagination?.current_page || 1);
      setPageSize(data.pagination?.per_page || limit);
      setTotalItems(data.pagination?.total || 0);
      setLastPage(data.pagination?.last_page || 1);
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      setError(`Error fetching data: ${errorMessage}`);
      message.error(`Error fetching data: ${errorMessage}`);
    } finally {
      setBusy(false);
      setText("Filter Report");
    }
  };
  // Clear all filters
  const clearFilters = () => {
    setReportAliasId("");
    setProviderId("");
    setProvider("");
    setVehicle("");
    setVehicleId("");
    setRoute("");
    setRouteId("");
    setStation("");
    setStationId("");
    setStudentType("");
    setStudentClass("");
    setStudentId("");
    setFilteredStations(originalStations);
    setFilteredVehicles(originalVehicles);
  };

  const getExportData = (data, isAllPages = false) => {
    return data.map((item, index) => ({
      serialNumber: isAllPages
        ? index + 1
        : (currentPage - 1) * pageSize + index + 1,
      ...item,
    }));
  };

  const exportCurrentPageExcel = () => {
    const augmentedData = getExportData(results, false);
    exportToExcel(augmentedData, "Vehicle Report");
    message.success("Current page exported successfully as Excel.");
  };

  const exportCurrentPageCSV = () => {
    const augmentedData = getExportData(results, false);
    exportToCSV(augmentedData, "Vehicle Report");
    message.success("Current page exported successfully as CSV.");
  };

  const exportCurrentPagePDF = () => {
    const augmentedData = getExportData(results, false);
    exportToPDF(
      exportColumns,
      augmentedData,
      "Vehicle Report",
      "Vehicle Report",
      [0, 0, 255],
      "/img/go-green.png",
      metadata
    );
    message.success("Current page exported successfully as PDF.");
  };

  const fetchAllData = async () => {
    setBusy(true);
    let allData = [];
    let aggregates = null;

    try {
      for (let page = 1; page <= lastPage; page++) {
        const url = `${baseUrl}/fastapi/vehicle/report/`;
        const params = {
          //database_name: "mec_school",
          unique_report_id: uniqueReportId,
          provider_id: providerId,
          vehicle_id: vehicleId,
          route_id: routeId,
          station_id: stationId,
          student_type: studentType,
          student_class: studentClass,
          student_id: studentId,
          page,
          per_page: pageSize,
        };

        const res = await axios.get(url, {
          params,
          headers: { Authorization: `Bearer ${token}` },
        });

        allData = allData.concat(res.data.data);

        // Store aggregates from the last page
        if (page === lastPage) {
          aggregates = res.data.aggregates;
        }
      }

      const dedupedData = allData.filter(
        (item, index, self) => index === self.findIndex((t) => t.id === item.id)
      );

      if (aggregates) {
        dedupedData.push({ type: "aggregates", ...aggregates });
      }

      return dedupedData;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      setError(`Error fetching all pages: ${errorMessage}`);
      message.error(`Error fetching all pages: ${errorMessage}`);
      return null;
    } finally {
      setBusy(false);
    }
  };

  const exportAllPagesExcel = async () => {
    setExporting(true);
    const allData = await fetchAllData();
    if (allData && allData.length > 0) {
      const augmentedData = getExportData(allData, true);
      exportToExcel(augmentedData, "AllPagesData");
      message.success("All pages exported successfully as Excel.");
    } else {
      message.warning("No data available to export.");
    }
    setExporting(false);
  };

  const exportAllPagesCSV = async () => {
    setExporting(true);
    const allData = await fetchAllData();
    if (allData && allData.length > 0) {
      const augmentedData = getExportData(allData, true);
      exportToCSV(augmentedData, "AllPagesData");
      message.success("All pages exported successfully as CSV.");
    } else {
      message.warning("No data available to export.");
    }
    setExporting(false);
  };

  const exportAllPagesPDF = async () => {
    setExporting(true);
    const allData = await fetchAllData();
    if (allData && allData.length > 0) {
      const augmentedData = getExportData(allData, true);
      exportToPDF(
        exportColumns,
        augmentedData,
        "AllPagesData",
        "All Pages Data Report",
        [0, 0, 255],
        "/img/go-green.png",
        metadata
      );
      message.success("All pages exported successfully as PDF.");
    } else {
      message.warning("No data available to export.");
    }
    setExporting(false);
  };

  const calculateTotals = (aggregates) => {
    if (!aggregates) return null;

    return {
      serialNumber: "Total",
      Adm: "",
      Name: "",
      student_class: "",
      Vehicle: "",
      provider: "",
      station_name: "",
      route_name: "",
      ToSchoolSchedules: "",
      FromSchoolSchedules: "",
      Trips_ToSchoolCount: aggregates.Trips_ToSchoolCount || 0,
      Trips_FromSchoolCount: aggregates.Trips_FromSchoolCount || 0,
      Total_trips_count: aggregates.Total_trips_count || 0,
      ToFrom_School_Distinct_Days_all:
        aggregates.ToFrom_School_Distinct_Days_all || 0,
      Subscription: "",
      Cost: aggregates.Cost || 0,
      PerVehicleCost_BasedOnTrips: aggregates.PerVehicleCost_BasedOnTrips || 0,
      PerVehicleCost_BasedOnSchedules:
        aggregates.PerVehicleCost_BasedOnSchedules || 0,
    };
  };

  const exportColumns = [
    {
      title: "S.No",
      dataIndex: "serialNumber",
      key: "serialNumber",
      width: 50,
    },
    // { title: "id", dataIndex: "id", key: "id" },
    { title: "Adm", dataIndex: "Adm", key: "Adm" },
    { title: "Name", dataIndex: "Name", key: "Name" },
    { title: "Class", dataIndex: "student_class", key: "student_class" },
    { title: "Vehicle", dataIndex: "Vehicle", key: "Vehicle" },
    { title: "Provider", dataIndex: "provider", key: "provider" },
    { title: "Station", dataIndex: "station_name", key: "station_name" },
    { title: "Route", dataIndex: "route_name", key: "route_name" },
    {
      title: "To School Schedules",
      dataIndex: "ToSchoolSchedules",
      key: "ToSchoolSchedules",
    },
    {
      title: "From School Schedules",
      dataIndex: "FromSchoolSchedules",
      key: "FromSchoolSchedules",
    },
    {
      title: "Trips To School",
      dataIndex: "Trips_ToSchoolCount",
      key: "Trips_ToSchoolCount",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "0"),
    },
    {
      title: "Trips From School",
      dataIndex: "Trips_FromSchoolCount",
      key: "Trips_FromSchoolCount",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "0"),
    },
    {
      title: "Total Trips",
      dataIndex: "Total_trips_count",
      key: "Total_trips_count",
    },
    {
      title: "Distinct Days",
      dataIndex: "ToFrom_School_Distinct_Days_all",
      key: "ToFrom_School_Distinct_Days_all",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "0"),
    },
    { title: "Subscription", dataIndex: "Subscription", key: "Subscription" },
    {
      title: "Termly Cost",
      dataIndex: "Cost",
      key: "Cost",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "0"),
    },
    {
      title: "Cost (Trips)",
      dataIndex: "PerVehicleCost_BasedOnTrips",
      key: "PerVehicleCost_BasedOnTrips",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "0"),
    },
    // {
    //   title: "Cost (Schedules)",
    //   dataIndex: "PerVehicleCost_BasedOnSchedules",
    //   key: "PerVehicleCost_BasedOnSchedules",
    //   render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "0"),
    // },
  ];

  const metadata = {
    reportName,
    startDate,
    endDate,
    noTranches,
    minTrips,
    percentage,
  };

  const tableColumns = [
    {
      title: "#",
      key: "index",
      width: 60,
      render: (text, record, index) => (
        <span>{(currentPage - 1) * pageSize + index + 1}</span>
      ),
    },
    ...exportColumns.slice(1),
  ];

  const dropdownStyle = {
    backgroundColor: "#ffffff",
    color: "#000000",
  };

  return (
    <div style={{ marginTop: 30 }}>
      <h4 style={{ textAlign: "center", marginBottom: 20 }}>Vehicle Report</h4>

      <div style={{ width: "80%", margin: "0 auto", marginTop: 30 }}>
        <Row gutter={[16, 16]} style={{ marginBottom: "40px" }}>
          {/* Provider Filter */}
          <Col span={8}>
            <label>Provider</label>
            <Select
              placeholder="Select Provider"
              value={provider}
              onChange={handleProviderChange}
              onSearch={(value) => debouncedSearch(value, "provider")}
              showSearch
              filterOption={false}
              style={{ width: "100%" }}
              dropdownStyle={dropdownStyle}
            >
              {filteredProviders.map((provider) => (
                <Option key={provider.provider_id} value={provider.provider_id}>
                  {provider.provider}
                </Option>
              ))}
            </Select>
          </Col>

          {/* Vehicle Filter */}
          <Col span={8}>
            <label>Vehicle</label>
            <Select
              placeholder="Select Vehicle"
              value={vehicle}
              onChange={(value) => {
                setVehicle(value);
                setVehicleId(value);
              }}
              onSearch={(value) => debouncedSearch(value, "vehicle")}
              showSearch
              filterOption={false}
              style={{ width: "100%" }}
              dropdownStyle={dropdownStyle}
            >
              {filteredVehicles.map((vehicle) => (
                <Option key={vehicle.vehicle_id} value={vehicle.vehicle_id}>
                  {vehicle.vehicle}
                </Option>
              ))}
            </Select>
          </Col>

          {/* Route Filter */}
          <Col span={8}>
            <label>Route</label>
            <Select
              placeholder="Select Route"
              value={route}
              onChange={handleRouteChange}
              onSearch={(value) => debouncedSearch(value, "route")}
              showSearch
              filterOption={false}
              style={{ width: "100%" }}
              dropdownStyle={dropdownStyle}
            >
              {filteredRoutes.map((route) => (
                <Option key={route.route_id} value={route.route_id}>
                  {route.route_name}
                </Option>
              ))}
            </Select>
          </Col>

          {/* Station Filter */}
          <Col span={8}>
            <label>Station</label>
            <Select
              placeholder="Select Station"
              value={station}
              onChange={(value) => {
                setStation(value);
                setStationId(value);
              }}
              onSearch={(value) => debouncedSearch(value, "station")}
              showSearch
              filterOption={false}
              style={{ width: "100%" }}
              dropdownStyle={dropdownStyle}
            >
              {filteredStations.map((station) => (
                <Option key={station.station_id} value={station.station_id}>
                  {station.station_name}
                </Option>
              ))}
            </Select>
          </Col>

          {/* Student Type Filter */}
          <Col span={8}>
            <label htmlFor="studentType">Student Type</label>
            <Select
              placeholder="Select Student Type"
              value={studentType}
              onChange={(value) => setStudentType(value)}
              onSearch={(value) => debouncedSearch(value, "studentType")}
              showSearch
              filterOption={false}
              style={{ width: "100%" }}
              dropdownStyle={dropdownStyle}
            >
              {filteredStudentTypes.map((type) => (
                <Option key={type} value={type}>
                  {type}
                </Option>
              ))}
            </Select>
          </Col>

          {/* Student Class Filter */}
          <Col span={8}>
            <label htmlFor="studentClass">Student Class</label>
            <Select
              placeholder="Select Student Class"
              value={studentClass}
              onChange={(value) => setStudentClass(value)}
              onSearch={(value) => debouncedSearch(value, "studentClass")}
              showSearch
              filterOption={false}
              style={{ width: "100%" }}
              dropdownStyle={dropdownStyle}
            >
              {filteredStudentClasses.map((studentClass) => (
                <Option key={studentClass} value={studentClass}>
                  {studentClass}
                </Option>
              ))}
            </Select>
          </Col>

          {/* Student Filter */}
          <Col span={8}>
            <label>Student</label>
            <Select
              placeholder="Select Student"
              value={studentId}
              onChange={setStudentId}
              onSearch={(value) => debouncedSearch(value, "student")}
              showSearch
              filterOption={false}
              style={{ width: "100%" }}
              dropdownStyle={dropdownStyle}
            >
              {filteredStudents.map((student) => (
                <Option key={student.student_id} value={student.student_id}>
                  {student.name}
                </Option>
              ))}
            </Select>
          </Col>

          {/* Clear Filters Button */}
          <Col span={8}>
            <Button
              type="default"
              onClick={clearFilters}
              style={{ width: "100%", marginTop: "26px" }}
            >
              Clear Filters
            </Button>
          </Col>

          {/* Generate Report Button */}
          <Col span={8}>
            <Button
              type="primary"
              onClick={() => generateReport(1, pageSize)}
              loading={busy}
              disabled={!token || busy}
              style={{ width: "100%", marginTop: "26px" }}
            >
              {text}
            </Button>
          </Col>
        </Row>
      </div>

      <hr />

      {error && <Alert message={error} type="error" showIcon />}
      {/* Report Metadata Header */}
      {results.length > 0 && (
        <div
          style={{
            backgroundColor: "#ffffff",
            padding: "20px",
            borderRadius: "8px",
            marginBottom: "20px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            border: "1px solid #e8e8e8",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* Report Details Heading */}
          <h3
            style={{
              textAlign: "center",
              marginBottom: "20px",
              fontSize: "18px",
              fontWeight: "600",
              color: "#00AF50",
            }}
          >
            Report Details
          </h3>

          {/* Metadata Fields */}
          <Row
            gutter={[64, 16]}
            style={{
              width: "100%",
              display: "flex",
              flexWrap: "nowrap",
              justifyContent: "center",
            }} // Center metadata fields
          >
            {/* Unique Report ID */}
            <Col>
              <div
                style={{
                  display: "flex",
                  fontSize: "15px",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <strong style={{ color: "#595959" }}>Unique Report ID:</strong>
                <span style={{ color: "#262626", fontWeight: "500" }}>
                  {results[0]?.unique_report_id || "N/A"}
                </span>
              </div>
            </Col>

            {/* Report Name */}
            <Col>
              <div
                style={{
                  display: "flex",
                  fontSize: "15px",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <strong style={{ color: "#595959" }}>Report Name:</strong>
                <span style={{ color: "#262626", fontWeight: "500" }}>
                  {reportName || "N/A"}
                </span>
              </div>
            </Col>

            {/* Start Date */}
            <Col>
              <div
                style={{
                  display: "flex",
                  fontSize: "15px",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <strong style={{ color: "#595959" }}>Start Date:</strong>
                <span style={{ color: "#262626", fontWeight: "500" }}>
                  {startDate ? moment(startDate).format("YYYY-MM-DD") : "N/A"}
                </span>
              </div>
            </Col>

            {/* End Date */}
            <Col>
              <div
                style={{
                  display: "flex",
                  fontSize: "15px",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <strong style={{ color: "#595959" }}>End Date:</strong>
                <span style={{ color: "#262626", fontWeight: "500" }}>
                  {endDate ? moment(endDate).format("YYYY-MM-DD") : "N/A"}
                </span>
              </div>
            </Col>

            {/* No. of Tranches */}
            <Col>
              <div
                style={{
                  display: "flex",
                  fontSize: "15px",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <strong style={{ color: "#595959" }}>No. of Tranches:</strong>
                <span style={{ color: "#262626", fontWeight: "500" }}>
                  {noTranches || "N/A"}
                </span>
              </div>
            </Col>

            {/* Minimum Trips */}
            <Col>
              <div
                style={{
                  display: "flex",
                  fontSize: "15px",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <strong style={{ color: "#595959" }}>Minimum Trips:</strong>
                <span style={{ color: "#262626", fontWeight: "500" }}>
                  {minTrips || "N/A"}
                </span>
              </div>
            </Col>

            {/* Percentage */}
            <Col>
              <div
                style={{
                  display: "flex",
                  fontSize: "15px",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <strong style={{ color: "#595959" }}>Percentage:</strong>
                <span style={{ color: "#262626", fontWeight: "500" }}>
                  {percentage ? `${percentage}%` : "N/A"}
                </span>
              </div>
            </Col>
          </Row>
        </div>
      )}

      <Spin spinning={busy || exporting}>
        {results.length > 0 ? (
          <div
            style={{
              width: "90%",
              margin: "auto",
              marginTop: "40px",
              overflowX: "auto",
            }}
          >
            <Row justify="end" style={{ marginBottom: 20 }}>
              <Col>
                {/* Export Current Page Buttons */}
                <Button
                  type="primary"
                  onClick={exportCurrentPageExcel}
                  style={{ marginRight: 10 }}
                  disabled={exporting}
                  icon={<FileExcelOutlined />}
                >
                  <FileExcelOutlined style={{ marginRight: 5 }} /> Export
                  Current Pages (Excel)
                </Button>
                <Button
                  type="primary"
                  onClick={exportCurrentPageCSV}
                  style={{ marginRight: 10 }}
                  disabled={exporting}
                >
                  <FileTextOutlined style={{ marginRight: 5 }} /> Export Current
                  Page (CSV)
                </Button>
                <Button
                  type="primary"
                  onClick={exportCurrentPagePDF}
                  style={{ marginRight: 10 }}
                  disabled={exporting}
                >
                  <FilePdfOutlined style={{ marginRight: 5 }} /> Export Current
                  Page (PDF)
                </Button>

                {/* Export All Pages Buttons */}
                <Button
                  type="default"
                  onClick={exportAllPagesExcel}
                  style={{
                    marginRight: 10,
                    borderColor: "green",
                    color: "green",
                  }}
                  disabled={exporting}
                >
                  <FileExcelOutlined style={{ marginRight: 5 }} /> Export All
                  Pages (Excel)
                </Button>
                <Button
                  type="default"
                  onClick={exportAllPagesCSV}
                  style={{
                    marginRight: 10,
                    borderColor: "green",
                    color: "green",
                  }}
                  disabled={exporting}
                >
                  <FileTextOutlined style={{ marginRight: 5 }} /> Export All
                  Page (CSV)
                </Button>
                <Button
                  type="default"
                  onClick={exportAllPagesPDF}
                  style={{
                    marginRight: 10,
                    borderColor: "green",
                    color: "green",
                  }}
                  disabled={exporting}
                >
                  <FilePdfOutlined style={{ marginRight: 5 }} /> Export All Page
                  (PDF)
                </Button>
              </Col>
            </Row>

            <Table
              columns={tableColumns}
              dataSource={results}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: totalItems,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "50", "100"],
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                onChange: (page, pageSize) => {
                  setCurrentPage(page);
                  setPageSize(pageSize);
                  generateReport(page, pageSize);
                },
              }}
              rowKey="id"
              bordered
            />
          </div>
        ) : (
          <div style={{ textAlign: "center", marginTop: 40 }}>
            <Empty
              description="No data available. Please generate a report."
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </div>
        )}
      </Spin>
    </div>
  );
};

export default VehicleReport;
