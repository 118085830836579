import React, { Component } from "react";
import { Layout, Table, Button, DatePicker } from "antd";
import moment from "moment";
import axios from "axios";

const { Content } = Layout;

class AllVehicles extends Component {
  state = {
    vehicles: [],
    date: moment().format("YYYY-MM-DD"),
    loading: false,
    buttonDisabled: true,
  };

  componentDidMount() {
    this.fetchVehicleData();
  }

  fetchVehicleData = () => {
    this.setState({ loading: true, buttonDisabled: true });
    axios
      .get(`/geolocation/all/vehicles?date=${this.state.date}`)
      .then((response) => {
        const vehicles = response.data.data.map((vehicle) => ({
          trip_id: vehicle.trip_info.trip_id,
          driver_name: vehicle.trip_info.driver_name,
          schedule_name: vehicle.trip_info.schedule_name,
          Direction: vehicle.trip_info.Direction,
          route_name: vehicle.trip_info.RouteName,
          plate_number: vehicle.trip_info.PlateNo,
          vehicleLocations: vehicle.geolocations.map((location) => ({
            lat: parseFloat(location.latitude),
            lng: parseFloat(location.longitude),
            timestamp: location.timestamp,
            plate_number: vehicle.trip_info.PlateNo,
          })),
        }));

        this.setState({
          vehicles,
          loading: false,
          buttonDisabled: false,
        });
      })
      .catch((error) => {
        console.error("Error fetching vehicle data:", error);
        this.setState({ loading: false, buttonDisabled: false });
      });
  };

  handleDateChange = (date) => {
    this.setState({ date: date.format("YYYY-MM-DD") }, this.fetchVehicleData);
  };

  handleViewLiveLocation = () => {
    const { vehicles } = this.state;

    localStorage.setItem("vehicleLocations", JSON.stringify(vehicles));

    window.open(`all-vehicles-maps.html`, "_blank");
  };

  render() {
    const { vehicles, loading, buttonDisabled } = this.state;

    return (
      <Layout>
        <Content style={{ padding: "20px" }}>
          {/* Date Filter */}
          <div style={{ marginBottom: "20px" }}>
            <DatePicker
              value={moment(this.state.date, "YYYY-MM-DD")}
              onChange={this.handleDateChange}
              format="YYYY-MM-DD"
            />
            {/* View Live Location Button */}
            <Button
              type="primary"
              style={{ marginLeft: "10px" }}
              disabled={buttonDisabled}
              onClick={this.handleViewLiveLocation}
            >
              View Live Location
            </Button>
          </div>

          <Table
            loading={loading}
            dataSource={vehicles}
            columns={[
              {
                title: "Plate Number",
                dataIndex: "plate_number",
                key: "plate_number",
              },
              {
                title: "Driver Name",
                dataIndex: "driver_name",
                key: "driver_name",
              },
              {
                title: "Schedule Name",
                dataIndex: "schedule_name",
                key: "schedule_name",
              },
              {
                title: "Direction",
                dataIndex: "Direction",
                key: "Direction",
              },
              {
                title: "Route",
                dataIndex: "route_name",
                key: "route_name",
              },
            ]}
            rowKey="trip_id"
          />
        </Content>
      </Layout>
    );
  }
}

export default AllVehicles;
