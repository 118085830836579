import React, { Component } from "react";
import { subscribe } from "../../utils/bunifu-redux";
import Ant, {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Icon,
  Input,
  Layout,
  Menu,
  message,
  Radio,
  Row,
  Select,
  Table,
  Tabs,
  Tag,
} from "antd";
import axios from "axios";
import auth from "../../Components/Auth";
import { get, get2, keyValue, put } from "../../utils/Storage";
import moment from "moment";
import searchCol from "../../Components/SearchCol";
import SortAlphabetic from "../../Components/SortAlphabetic";
import SortNumeric from "../../Components/SortNumeric";
import _ from "linq";
import kimTools from "../../utils/KimTools";
import Go from "../../Server/Go";

class Vehicles extends Component {
  state = {
    name: "Vehicles",
    schedules: get("schedules"),
    page: "All",
    month: moment().format("MM"),
    year: moment().format("YYYY"),
    date: moment().format("YYYY") + "-" + moment().format("MM") + "-01",
    loadingTrips: false,
    loadingVehicles: true,
    edit: -1,
    sync: true,
    filters: [],
    currentDataSource: [],
    data: [],
    tripsData: [],
    providers: _.from(get("providers")),
    permissions: get2("permissions"),
  };

  componentWillMount() {
    subscribe(this);
    this.LoadData();
  }

  LoadData = () => {
    this.setState({ loadingVehicles: true });

    const baseUrl = localStorage.getItem("api");
    if (!baseUrl) {
      message.error("API URL not found. Please configure your settings.");
      this.setState({ loadingVehicles: false });
      return;
    }

    axios
      .get(`${baseUrl}/vehicle`)
      .then((response) => {
        console.log("API Response:", response.data);

        // Store data in local storage
        put("vehicles", response.data);
        put("vehicles-map", keyValue(response.data));
        console.log("Stored Vehicles:", get("vehicles"));

        let vehicles = _.from(response.data);
        if (this.props.provider)
          vehicles = vehicles.where(
            (r) => r.ProviderId == this.props.provider.id
          );
        console.log("Filtered Vehicles:", vehicles.toArray());

        // Update state
        this.setState(
          { data: vehicles.toArray(), loadingVehicles: false },
          () => {
            console.log("Updated State:", this.state.data);
          }
        );
      })
      .catch((error) => {
        console.error("API Error:", error);
        message.error("Failed to fetch vehicle data.");
        this.setState({ loadingVehicles: false });
      });
  };

  update = (obj) => {
    if (obj.id >= 0) {
      axios
        .patch("/vehicle", obj)
        .then(() => {
          put("vehicles", this.state.data);
          put("vehicles-map", keyValue(this.state.data));
          message.success("Saved");
        })
        .catch((error) => {
          message.error(error.message);
        });
    } else {
      // Post new vehicle
      axios
        .post("/vehicle", obj)
        .then((response) => {
          this.sync();
          const updatedData = [response.data, ...this.state.data];
          this.setState({
            data: updatedData,
            edit: response.data.id,
          });
          put("vehicles", updatedData);
          put("vehicles-map", keyValue(updatedData));
          message.success("Added");
        })
        .catch((error) => {
          message.error(error.message);
        });
    }
  };

  getProviders = () => {
    const providers = [];
    get("vehicles").forEach((vehicle) => {
      if (!providers.includes(vehicle.Provider)) {
        providers.push(vehicle.Provider);
      }
    });
    return providers;
  };

  render() {
    const {
      filters,
      currentDataSource,
      providers,
      data,
      loadingVehicles,
      page,
      schedules,
      permissions,
    } = this.state;

    const expandedRowRender = (row) => {
      const vehicleSchedules = schedules.filter((r) => r.VehicleId === row.id);

      const columns = [
        {
          title: "",
          dataIndex: "Name",
          key: "Name",
          render: (val) => (
            <Avatar
              size={"small"}
              style={{
                backgroundColor: window.getColor(val + " null", 50, 50),
              }}
            >
              <Icon type="calendar" />
            </Avatar>
          ),
        },
        { title: "Name", dataIndex: "Name", key: "Name" },
        {
          title: "Driver",
          dataIndex: "DriverId",
          key: "DriverId",
          render: (val) => <Tag>{get("users-map")[val].name}</Tag>,
        },
        {
          title: "Agent",
          dataIndex: "AttendantId",
          key: "AttendantId",
          render: (val) => <Tag>{get("users-map")[val].name}</Tag>,
        },
        { title: "Direction", dataIndex: "Direction", key: "Direction" },
        {
          title: "Route",
          dataIndex: "Route",
          key: "Route",
          render: (val) => <Tag>{val}</Tag>,
        },
      ];

      return (
        <Table
          loading={this.state.loadingTrips}
          className={"shadow"}
          style={{ padding: 20 }}
          size={"middle"}
          columns={columns}
          dataSource={vehicleSchedules}
          pagination={false}
        />
      );
    };

    const columns = [
      {
        title: "",
        dataIndex: "Status",
        key: "Status",
        width: 125,
        visible: this.props.provider === undefined,
        render: (val) => (
          <Tag color={val === "Scheduled" ? "limegreen" : "red"}> {val}</Tag>
        ),
      },
      {
        title: "PlateNo",
        dataIndex: "PlateNo",
        key: "PlateNo",
        ...searchCol(this, "PlateNo"),
        sorter: (a, b) => SortAlphabetic(a.PlateNo, b.PlateNo),
        render: (val, obj, idx) =>
          obj.id === this.state.edit ? (
            <span>
              <Input
                onChange={(e) => {
                  const index = this.state.data.findIndex(
                    (r) => r.id === obj.id
                  );
                  const updatedData = [...this.state.data];
                  updatedData[index].PlateNo = e.target.value.toUpperCase();
                  this.setState({ data: updatedData });
                }}
                value={val.toString().toUpperCase()}
              />
            </span>
          ) : (
            <span>{val}</span>
          ),
      },
      {
        title: "Model",
        dataIndex: "Model",
        key: "Model",
        ...searchCol(this, "Model"),
        sorter: (a, b) => SortAlphabetic(a.Model, b.Model),
        render: (val) => <span>{kimTools.strings.toTitleCase(val)}</span>,
      },
      {
        title: "Provider",
        dataIndex: "ProviderId",
        key: "ProviderId",
        visible: false,
        filters: [
          ...providers
            .select((pr) => ({ text: pr.Name, value: pr.id }))
            .toArray(),
          { text: "(None)", value: "" },
        ],
        filterMultiple: true,
        onFilter: (value, record) => `${record.ProviderId}` === `${value}`,
        sorter: (a, b) => SortAlphabetic(a.ProviderId, b.ProviderId),
        render: (val) => (
          <span>{val > 0 ? get("providers-map")[val].Name : "Unknown"}</span>
        ),
      },
      {
        title: "Color",
        dataIndex: "Color",
        key: "Color",
        sorter: (a, b) => SortAlphabetic(a.Color, b.Color),
        render: (val, obj) =>
          obj.id === this.state.edit ? (
            <span>
              <Input
                onPressEnter={() => {
                  this.update(obj);
                  this.setState({ edit: -2 });
                }}
                onChange={(e) => {
                  const index = this.state.data.findIndex(
                    (r) => r.id === obj.id
                  );
                  const updatedData = [...this.state.data];
                  updatedData[index].Color = e.target.value;
                  this.setState({ data: updatedData });
                }}
                value={val}
              />
            </span>
          ) : (
            <Tag color={val}>
              <Icon type="car" /> {val}
            </Tag>
          ),
      },
      {
        title: "Capacity",
        dataIndex: "Capacity",
        key: "Capacity",
        sorter: (a, b) => SortNumeric(a.Capacity, b.Capacity),
        render: (val, obj) =>
          obj.id === this.state.edit ? (
            <span>
              <Input
                onChange={(e) => {
                  const index = this.state.data.findIndex(
                    (r) => r.id === obj.id
                  );
                  const updatedData = [...this.state.data];
                  updatedData[index].Capacity = e.target.value;
                  this.setState({ data: updatedData });
                }}
                value={val}
              />
            </span>
          ) : (
            <span>{val}</span>
          ),
      },
      {
        title: "Schedules",
        dataIndex: "id",
        sorter: (a, b) =>
          SortNumeric(
            _.from(schedules).count((r) => r.VehicleId === a.id),
            _.from(schedules).count((r) => r.VehicleId === b.id)
          ),
        render: (val) => (
          <Tag color={val.Status === "Scheduled" ? "limegreen" : "red"}>
            {schedules.filter((r) => r.VehicleId === val).length}
          </Tag>
        ),
      },
      {
        title: "",
        dataIndex: "id",
        key: "id",
        render: (id, obj) => (
          <span>
            {permissions.get(12) && permissions.get(12).can_modify == 1 ? (
              <a
                hidden={auth.noUpdate(auth.Vehicles)}
                href={"#/m-vehicle?id=" + id}
              >
                {" "}
                <Icon
                  type="edit"
                  style={{ color: "gray" }}
                  onClick={() => this.setState({ edit: id })}
                />{" "}
              </a>
            ) : null}
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    disabled={auth.noView(auth.AgentActivityReport)}
                    onClick={() => window.pop("/wwwroot/?vehicles&id=" + id)}
                    hidden={!auth.View(auth.AgentActivityReport)}
                    key="1"
                  >
                    Vehicle Summary
                  </Menu.Item>
                  <Menu.Item
                    disabled={auth.noView(auth.ProviderActivityReport)}
                    onClick={() =>
                      window.pop("/wwwroot/?providers&id=" + obj.ProviderId)
                    }
                    hidden={!auth.View(auth.ProviderActivityReport)}
                  >
                    Provider Summary
                  </Menu.Item>
                </Menu>
              }
            >
              <a onClick={(e) => e.preventDefault()}>
                <Icon
                  style={{ color: "gray" }}
                  type="appstore"
                  theme="filled"
                />
              </a>
            </Dropdown>
          </span>
        ),
      },
    ];

    let dataFiltered = data;

    const active = dataFiltered.filter(
      (r) => schedules.filter((rr) => rr.VehicleId === r.id).length > 0
    );
    const inActive = dataFiltered.filter(
      (r) => schedules.filter((rr) => rr.VehicleId === r.id).length === 0
    );
    const all = dataFiltered;

    let _context = all;

    if (page === "Active") _context = active;
    else if (page === "InActive") _context = inActive;

    _context = _.from(_context)
      .select((r) => {
        r.Status =
          schedules.filter((rr) => rr.VehicleId === r.id).length > 0
            ? "Scheduled"
            : "Pending";
        return r;
      })
      .toArray();

    const operations = (
      <span>
        <Button
          type="normal"
          onClick={this.LoadData}
          shape="circle"
          icon="sync"
          size={"small"}
        />{" "}
        &nbsp;
        {permissions.get(12) && permissions.get(12).can_add == 1 ? (
          <a hidden={auth.noInsert(auth.Vehicles)} href={"#/m-vehicle"}>
            {" "}
            <Button type="primary" shape="round" icon="plus" size="small">
              Add Vehicle
            </Button>
          </a>
        ) : null}
      </span>
    );

    return (
      <Layout.Content>
        <Card bordered={false}>
          <Radio.Group
            value={page}
            buttonStyle="solid"
            onChange={(e) => this.setState({ page: e.target.value })}
          >
            <Radio.Button value="All">All ({all.length})</Radio.Button>
            <Radio.Button value="Active">
              In Service ({active.length})
            </Radio.Button>
            <Radio.Button value="Inactive">
              Out of Service ({inActive.length})
            </Radio.Button>
          </Radio.Group>
          <br /> <br />
          <Tabs defaultActiveKey="1" tabBarExtraContent={operations}>
            <Tabs.TabPane
              tab={
                <span>
                  <Icon type="team" />
                  Vehicles ({dataFiltered.length})
                </span>
              }
              key="1"
            >
              <Row gutter={5}>
                <Col span={24}>
                  <Table
                    size={"middle"}
                    dataSource={_context}
                    columns={columns}
                    expandedRowRender={expandedRowRender}
                    onChange={(pagination, filters, sorter, extra) => {
                      const selFilters = [];
                      for (const filter in filters) {
                        if (filters.hasOwnProperty(filter)) {
                          const el = { name: filter, data: filters[filter] };
                          if (el.data.length > 0) selFilters.push(el);
                        }
                      }

                      this.setState({
                        filters: selFilters,
                        currentDataSource: extra.currentDataSource,
                      });
                    }}
                    footer={() => (
                      <>
                        <span style={{ paddingRight: 10 }}>
                          Records:{" "}
                          <strong>{data.length.toLocaleString()}</strong>
                        </span>
                        <span style={{ paddingRight: 10 }}>
                          Filtered:{" "}
                          <strong>
                            {currentDataSource.length.toLocaleString()}
                          </strong>
                        </span>
                      </>
                    )}
                  />
                </Col>
              </Row>
            </Tabs.TabPane>
          </Tabs>
        </Card>
      </Layout.Content>
    );
  }
}

export default Vehicles;
