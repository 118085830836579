import React, { Component } from "react";
import {
  Table,
  Input,
  Button,
  message,
  Layout,
  Card,
  Popconfirm,
  Dropdown,
  Menu,
  Tooltip,
  Modal,
} from "antd";
import { MoreOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import axios from "axios";

class StudentTypes extends Component {
  state = {
    studentTypes: [],
    loading: false,
    newType: "",
    modalVisible: false,
    editingId: null,
    editingValue: "",
  };

  componentDidMount() {
    this.fetchStudentTypes();
  }

  fetchStudentTypes = () => {
    this.setState({ loading: true });
    const baseUrl = localStorage.getItem("api");

    if (!baseUrl) {
      message.error("API URL not found. Please configure your settings.");
      this.setState({ loading: false });
      return;
    }

    axios
      .get(`${baseUrl}/student/types`)
      .then((response) => {
        this.setState({ studentTypes: response.data, loading: false });
        localStorage.setItem("studentTypes", JSON.stringify(response.data));
      })
      .catch(() => {
        message.error("Failed to fetch student types.");
        this.setState({ loading: false });
      });
  };

  addStudentType = () => {
    const { newType, studentTypes } = this.state;
    if (!newType.trim()) {
      message.warning("Please enter a student type.");
      return;
    }

    const baseUrl = localStorage.getItem("api");
    if (!baseUrl) {
      message.error("API URL not found. Please configure your settings.");
      return;
    }

    axios
      .post(`${baseUrl}/student/types?Type=${newType}`)
      .then((response) => {
        message.success("Student type added successfully");
        const updatedTypes = [...studentTypes, response.data.data];
        this.setState({ studentTypes: updatedTypes, newType: "" });
        localStorage.setItem("studentTypes", JSON.stringify(updatedTypes));
      })
      .catch(() => {
        message.error("Failed to add student type.");
      });
  };

  updateStudentType = () => {
    const { editingValue, studentTypes, editingId } = this.state;

    if (!editingValue.trim()) {
      message.warning("Type cannot be empty.");
      return;
    }

    const baseUrl = localStorage.getItem("api");
    if (!baseUrl) {
      message.error("API URL not found. Please configure your settings.");
      return;
    }

    axios
      .patch(`${baseUrl}/student/types/${editingId}?Type=${editingValue}`)
      .then((response) => {
        message.success("Student type updated successfully");

        const updatedTypes = studentTypes.map((type) =>
          type.id === editingId ? response.data.data : type
        );

        this.setState({
          studentTypes: updatedTypes,
          modalVisible: false,
          editingId: null,
          editingValue: "",
        });
        localStorage.setItem("studentTypes", JSON.stringify(updatedTypes));
      })
      .catch(() => {
        message.error("Failed to update student type.");
      });
  };

  deleteStudentType = (id) => {
    const baseUrl = localStorage.getItem("api");
    if (!baseUrl) {
      message.error("API URL not found. Please configure your settings.");
      return;
    }

    axios
      .delete(`${baseUrl}/student/types/${id}`)
      .then(() => {
        message.success("Student type deleted successfully");

        const updatedTypes = this.state.studentTypes.filter(
          (type) => type.id !== id
        );
        this.setState({ studentTypes: updatedTypes });
        localStorage.setItem("studentTypes", JSON.stringify(updatedTypes));
      })
      .catch(() => {
        message.error("Failed to delete student type.");
      });
  };

  openEditModal = (id, Type) => {
    this.setState({ modalVisible: true, editingId: id, editingValue: Type });
  };

  closeEditModal = () => {
    this.setState({ modalVisible: false, editingId: null, editingValue: "" });
  };

  handleInputChange = (e) => {
    this.setState({ editingValue: e.target.value });
  };

  renderActionsMenu = (record) => (
    <Menu>
      <Menu.Item
        key="edit"
        onClick={() => this.openEditModal(record.id, record.Type)}
      >
        <Tooltip title="Edit">
          <EditOutlined style={{ color: "#1890ff" }} /> Edit
        </Tooltip>
      </Menu.Item>
      <Menu.Item key="delete">
        <Popconfirm
          title="Are you sure you want to delete this type?"
          onConfirm={() => this.deleteStudentType(record.id)}
          okText="Yes"
          cancelText="No"
        >
          <Tooltip title="Delete">
            <DeleteOutlined style={{ color: "red" }} /> Delete
          </Tooltip>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  render() {
    const { studentTypes, loading, newType, modalVisible, editingValue } =
      this.state;

    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Student Type",
        dataIndex: "Type",
        key: "Type",
      },
      {
        title: "Actions",
        key: "actions",
        width: 100,
        render: (text, record) => (
          <Dropdown
            overlay={this.renderActionsMenu(record)}
            trigger={["click"]}
          >
            <span style={{ cursor: "pointer" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
                width="25"
                height="25"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
                />
              </svg>
            </span>
          </Dropdown>
        ),
      },
    ];

    return (
      <Layout.Content>
        <Card title="Manage Student Types" bordered={false}>
          <div style={{ marginBottom: 16 }}>
            <Input
              placeholder="Enter new student type"
              value={newType}
              onChange={(e) => this.setState({ newType: e.target.value })}
              style={{ width: 300, marginRight: 10 }}
            />
            <Button type="primary" onClick={this.addStudentType}>
              Add Type
            </Button>
          </div>
          <Table
            dataSource={studentTypes}
            columns={columns}
            rowKey="id"
            loading={loading}
            style={{ maxWidth: 1000 }}
          />
        </Card>

        {/* Edit Modal */}
        <Modal
          title="Edit Student Type"
          visible={modalVisible}
          onOk={this.updateStudentType}
          onCancel={this.closeEditModal}
          okText="Save"
          cancelText="Cancel"
        >
          <Input
            value={editingValue}
            onChange={this.handleInputChange}
            placeholder="Edit student type"
          />
        </Modal>
      </Layout.Content>
    );
  }
}

export default StudentTypes;
